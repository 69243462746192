<template>
    <v-card class="line-form-card">
        <v-form
          v-model="formIsValidated"
          ref="instructionform"
          :lazy-validation="false"
        >
          <v-col class="py-4 px-4" style="background-color: #3788d8;">
            <IOFormHeader
              :isInit="isInit"
              :editedItemIo="editedItemIo"
              :insGetterSetter="insGetterSetter"
              :formTitle="formTitle"
              :isLocalizationHeaderForm="isLocalizationHeaderForm"
              :acknowledgmentsAreAllOk="acknowledgmentsAreAllOk"
              :formIsValidated.sync="formIsValidated"
              :isLoadingSurcoucheConfig="isLoadingSurcoucheConfig"
              :brief="brief"
              v-on:saveNormal="saveNormal"
              v-on:saveAsDraft="saveAsDraft"
              v-on:saveAsReview="saveAsReview"
              v-on:saveAsValidated="saveAsValidated"
              v-on:close="emitClose"
              ref="io-form-header"
            ></IOFormHeader>
          </v-col>
          <div>
            <v-tabs
              centered
              background-color="#3788d8"
              slider-color="yellow"
              icons-and-text
              dark
              v-model="currentTab"
              class="io-form-tabs-header"
            >
              <v-row class=" wrap justify-space-around">
                <v-tab :href="`#tab-0`" ref="tabzero">
                  <span>{{getNameForTab('tab-0')}}</span>
                  <v-icon>insert_chart</v-icon>
                </v-tab>
                <v-tab :href="`#tab-1`" ref="tabone">
                  <v-badge
                    color="red"
                    left
                    :value="invalidInputs.tab1 > 0"
                    >
                    <template v-slot:badge>
                      <span>{{invalidInputs.tab1}}</span>
                    </template>
                    <span>{{getNameForTab('tab-1')}}</span>
                  </v-badge>
                  <v-icon>settings</v-icon>
                </v-tab>
                <v-tab :href="`#tab-2`" ref="tabtwo">
                  <v-badge
                    color="red"
                    left
                    :value="invalidInputs.tab2 > 0"
                    >
                    <template v-slot:badge>
                      <span>{{invalidInputs.tab2}}</span>
                    </template>
                    <span>{{getNameForTab('tab-2')}}</span>
                  </v-badge>
                  <v-icon>view_list</v-icon>
                </v-tab>
                <v-tab :href="`#tab-3`" ref="tabthree" @change="queryStatusLogs()">
                  <v-badge
                    color="red"
                    left
                    :value="invalidInputs.tab3 > 0"
                    >
                    <template v-slot:badge>
                      <span>{{invalidInputs.tab3}}</span>
                    </template>
                    <span>{{getNameForTab('tab-3')}}</span>
                  </v-badge>
                  <v-icon>assignment</v-icon>
                </v-tab>
                <v-tab :href="`#tab-4`" ref="tabfour">
                  <v-badge
                    color="red"
                    left
                    :value="countNotes > 0"
                  >
                    <template v-slot:badge>
                      <span>{{countNotes}}</span>
                    </template>
                    <span>{{getNameForTab('tab-4')}}</span>
                  </v-badge>
                  <v-icon>speaker_notes</v-icon>
                </v-tab>
              </v-row>
              <v-tabs-items v-model="currentTab" class="tabs-item-container">
                <div class="loading-barform" v-if="!isInit">
                  <v-progress-circular
                    :width="8"
                    :size="110"
                    color="primary"
                    indeterminate
                  >
                  </v-progress-circular>
                </div>
                <AcknowledgementsForm
                  v-if="isInit && displayAcknowledgment()"
                  :acknowledgements="acknowledgments"
                  :brief="brief"
                >
                </AcknowledgementsForm>

                <v-container v-if="isInit" class="line-form-container">
                  <v-tab-item
                    eager
                    :value="'tab-0'"
                  >
                    <TraderViewComponent
                      :brief="brief"
                      :funnel="funnel"
                      :external-id="editedItemIo"
                      :dsp="dsp"
                      :client-id="insGetterSetter.clientIdPerDsp"
                    >
                    </TraderViewComponent>
                  </v-tab-item>

                  <!-- BRIEF -->
                  <v-tab-item eager :value="'tab-1'">
                    <IOBriefTabForm
                      v-if="!isLoadingKeystoneSetting"
                      v-on:launchTimeOutBadge="launchTimeOutBadge"
                      :editedItem="editedItem"
                      :editedItemIo="editedItemIo"
                      :isInstruCreatedViaDataform="isInstruCreatedViaDataform"
                      :untie="untie"
                      :kpiToOptimizeOptions="kpiToOptimizeOptions"
                      :maxFrequencyValue="maxFrequencyValue"
                      :targetFrequencyValue="targetFrequencyValue"
                      :isKpiToOptimizeDisabled="isKpiToOptimizeDisabled"
                      :insGetterSetter="insGetterSetter"
                      :brief="brief"
                      :funnel="funnel"
                      :isEditForm="isEditForm"
                      :error-brief-rules-checker="errorBriefRulesChecker"
                      v-on:openAlertErrorDialog="openAlertErrorDialog"
                      v-on:openConfirmDialog="openConfirmDialog"
                      :keystoneMemberSetting="keystoneMemberSetting"
                      ref="io-brief-tab-form"
                    ></IOBriefTabForm>
                    <v-progress-circular
                      indeterminate
                      color="primary" v-if="isLoadingKeystoneSetting"
                    >
                    </v-progress-circular>
                  </v-tab-item>

                  <!-- INSTRUCTIONS -->
                  <v-tab-item eager :value="'tab-2'">

                    <v-row wrap justify-space-between>
                      <v-app-bar class="elevation-2 toolbar-instru-form mt-3">
                        <v-toolbar-items>
                          <div style="height: 100%;">
                            <v-btn @click="selectAllInstructions()" class="cursor-pointer">
                              <v-checkbox
                                class="mt-5"
                                :input-value="allInstructionsSelected"
                                :indeterminate="allInstructionsIndeterminate"
                              />
                              <span>select<br/>all</span>
                            </v-btn>
                          </div>
                        </v-toolbar-items>
                        <!-- BULK EDIT -->
                        <v-toolbar-items>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn v-on="on" @click="openBulkEditDialog()" text :disabled="selectedInstructions.length === 0">
                                Tool Kit
                              </v-btn>
                            </template>
                            <span>Modify all instructions at once</span>
                          </v-tooltip>
                        </v-toolbar-items>
                        <!-- USE CUSTOM ALGO -->
                        <v-toolbar-items>
                          <CheckboxButton
                              v-if="dsp === $DBM && insGetterSetter.revenueType != null && !isLoadingKeystoneSetting"
                              :model="insGetterSetter.useCustomAlgo"
                              :label="' Use Custom Algo'"
                              :hasToEmitFn="true"
                              v-on:fn="toggleUseCustomAlgo"
                              :disabled="isDisabledCustomAlgo()"
                              :loading="isLoadingKeystoneSetting"
                              :flat="true"
                              :withTooltip="true"
                              :tooltipMessage="tooltipUseCustomAlgo"
                              :tooltipPlacement="'bottom'"
                            />
                        </v-toolbar-items>
                        <!-- OTTO -->
                        <v-toolbar-items v-show="allowChangingOptiOtto">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <div v-on="on" style="height: 100%">
                                <v-btn @click="toggleOptiAuto()" class="cursor-pointer">
                                  <v-row class="mx-1">
                                    <v-icon :class="editedItem.opti_auto ? 'item-is-on adb' : 'item-is-off'" small>
                                      {{optiOttoIcon}}
                                    </v-icon>
                                    <span>{{editedItem.opti_auto ? ' Deactivate' : ' Activate' }}<br/>Otto</span>
                                  </v-row>
                                </v-btn>
                              </div>
                            </template>
                            <span>
                              {{tooltipOptiAuto}}
                            </span>
                          </v-tooltip>
                        </v-toolbar-items>
                        <!-- OTTO MIN VIZ -->
                        <v-toolbar-items v-show="allowChangingOptiOtto && allowOttoMinViz">
                          <div style="height: 100%">
                            <v-btn @click="toggleOttoMinViz()" class="cursor-pointer">
                              <v-row column class="justify-center">
                                  <v-checkbox :input-value="editedItem.otto_min_viz" style="padding-top: 60%;" />
                              </v-row>
                              <div class="mx-2">
                                with otto min viz
                              </div>
                            </v-btn>
                          </div>
                        </v-toolbar-items>
                        <!-- USE SURCOUCHE RATIO -->
                        <v-toolbar-items>
                          <v-tooltip bottom close-delay="1000" color="white">
                            <template v-slot:activator="{ on }">

                              <v-btn-toggle
                                v-model="editedItem.use_opti_ratio_surcouche"
                                mandatory
                                group
                              >
                                <!-- CSV ratio option -->
                                <v-btn v-on="on" elevation="1" class="ma-0" style="height: 100%; border: 1px;" text :value="false">
                                  <span>CSV<br/>ratio</span> <!-- csv ratio -->
                                </v-btn>
                                <!-- Surcouche ratio option -->
                                <v-tooltip top :disabled="!isUseOptiRatioSurcoucheDisabled()">
                                  <template v-slot:activator="{ on: onUseOptiRatio }">
                                    <div v-on="onUseOptiRatio">
                                      <v-btn style="height: 100%; border: 1px;" elevation="1" class="ma-0" text :value="true"
                                            :disabled="isUseOptiRatioSurcoucheDisabled()"
                                      >
                                        <span>surcouche<br/>ratio</span> <!-- surcouche ratio -->
                                      </v-btn>
                                    </div>
                                  </template>
                                  <span>
                                    Surcouche ratios are incompatible  with a 3rd party optimization KPI (selected in the brief).
                                  </span>
                                </v-tooltip>
                              </v-btn-toggle>

                            </template>
                            <div style="color: black">
                              Using csv ratio implies the upload of your 3rd party conversion file to this interface
                              <a :href="linkInterface5000ThirdPartyConv" rel="noopener noreferrer" target="_blank">{{linkInterface5000ThirdPartyConv}}</a> .
                              If no csv files have been uploaded, no ratio will be applied"
                            </div>
                            <div style="color: black"> Use surcouche ratio : {{ editedItem.use_opti_ratio_surcouche == null ? 'false' : editedItem.use_opti_ratio_surcouche.toString() }}</div>
                          </v-tooltip>
                        </v-toolbar-items>
                        <v-toolbar-items v-if="isSurcoucheForcedRatioAvailableForDsp()">
                          <v-btn-toggle v-model="currentInstructionSubView" tile variant="text">
                            <v-tooltip bottom :disabled="isSurcoucheForcedRatioAvailableForDsp()">
                              <template v-slot:activator="{ on }">
                                <div v-on="on">
                                  <v-btn :disabled="!isSurcoucheForcedRatioAvailableForDsp()" text class="ma-0" style="height: 100%; border: 1px; color: black;" elevation="1" :value="'ins-sub-view-1'">
                                    <span>forced<br/>ratio</span> <!-- forced ratio -->
                                  </v-btn>
                                </div>
                              </template>
                              <span>Not yet implemented for {{ dsp }}</span>
                            </v-tooltip>
                          </v-btn-toggle>
                        </v-toolbar-items>
                        <!-- OPEN OUTCOMES-->
                        <v-toolbar-items v-show="$store.getters.isInstructionStratEnabled">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn text icon v-on="on" color="blue darken-1" @click="emitOpenStrat(editedItem.io)">
                                <v-icon>remove_red_eye</v-icon>
                              </v-btn>
                            </template>
                            <span>Open outcomes for all the instructions</span>
                          </v-tooltip>
                        </v-toolbar-items>
                        <!-- go to dsp -->
                        <v-toolbar-items v-show="displayDspUrl">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn text icon v-on="on" color="blue darken-1" :href="getDspUrl()" rel="noopener noreferrer" target="_blank">
                                <v-icon small>launch</v-icon>
                              </v-btn>
                            </template>
                            <span>Go to dsp</span>
                          </v-tooltip>
                        </v-toolbar-items>
                        <v-toolbar-items v-if="activateRetryToggle">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn text icon v-on="on" color="blue darken-1"  @click="clickOnRetry()">
                                <v-icon v-on="on" class="size-icon-ins-header" :class="insGetterSetter.retry ? 'item-is-on refresh' : 'item-is-off'" small>refresh</v-icon>
                              </v-btn>
                            </template>
                            <span>retry : {{insGetterSetter.retry ? insGetterSetter.retry.toString() : false}}</span>
                          </v-tooltip>
                        </v-toolbar-items>
                        <v-toolbar-items>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn text icon v-on="on" color="blue darken-1"  @click="clickOnFastRetry()">
                                <v-icon v-on="on" class="size-icon-ins-header" :class="insGetterSetter.fastRetry ? 'item-is-on flash' : 'item-is-off'" small>flash_on</v-icon>
                              </v-btn>
                            </template>
                            <span>fast_retry : {{insGetterSetter.fastRetry ? insGetterSetter.fastRetry.toString() : false}}</span>
                          </v-tooltip>
                        </v-toolbar-items>
                        <v-toolbar-items v-if="allowChangingOptiOtto && !editedItem.opti_auto">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                              <v-btn text icon v-on="on" color="blue darken-1" @click="toggleReactivateOtto()">
                                <span class="material-symbols-outlined size-icon-ins-header" v-on="on"
                                      :class="insGetterSetter.reactivateOtto ? 'reactivate-otto-on' : 'reactivate-otto-off'">
                                  switch_access_shortcut_add
                                </span>
                              </v-btn>
                            </template>
                            <span>reactive otto : {{insGetterSetter.reactivateOtto ? insGetterSetter.reactivateOtto.toString() : false}}</span>
                          </v-tooltip>
                        </v-toolbar-items>
                      </v-app-bar>
                    </v-row>

                      <!-- EXPLODE DIALOG -->

                      <v-dialog
                        eager
                        v-model="dialogExplode"
                        width="500"
                        transition="slide-x-transition"
                        origin="center center"
                        :persistent="true"
                      >
                        <ExplodeDialog
                          :step="explodeDialogStep"
                          :hasOttoMinViz="editedItem.otto_min_viz"
                          :has-opti-auto="editedItem.opti_auto"
                          :is-processing-explode="isProcessingExplode"
                          v-on:explode="explodeInstruStratProcess"
                          v-on:no-explode="closeExplodeDialog"
                        >
                        </ExplodeDialog>
                      </v-dialog>

                      <!-- BULK EDIT DIALOG -->

                      <v-dialog
                        eager
                        v-model="dialogBulkEdit"
                        width="700"
                        transition="slide-x-transition"
                        origin="center center"
                        :persistent="true"
                      >
                        <BulkEditDialog
                          :selectedInstructions="selectedInstructions"
                          :dsp="dsp"
                          :isOptiAutoActive="editedItem.opti_auto"
                          :isOttoMinVizActive="editedItem.otto_min_viz"
                          :isSurcoucheRatioActive="editedItem.use_opti_ratio_surcouche"
                          v-on:apply-bulk-edit="applyBulkEditChanges"
                          v-on:cancel-bulk-edit="closeBulkEditDialog"
                          ref="bulkEditDialog"
                        />
                      </v-dialog>

                      <!-- OTTO MIN VIZ ERROR MESSAGE DIALOG -->

                      <v-dialog
                        eager
                        v-model="dialogAlertError"
                        width="500"
                        transition="slide-x-transition"
                        origin="center center"
                        :persistent="true"
                      >
                        <AlertErrorDialog
                          :errorText="ottoErrorText"
                          v-on:close-alert-error="closeAlertErrorDialog"
                        />
                      </v-dialog>

                    <!-- INSTRUCTION SUB VIEW: INSTRUCTION DEFAULT VIEW -->
                    <template v-if="currentInstructionSubView === 'ins-sub-view-0'">
                      <v-row wrap>
                        <!-- ERROR BOX MESSAGE INSTRUCTION CONTAINER -->

                        <v-col cols="1">
                          <v-text-field
                            :error-messages="rulesInstructionsContainer()"
                            class="invisible-input-slot-error-message"
                            tab="tab2"
                            :error-count="4"
                          >
                          </v-text-field>
                        </v-col>

                      </v-row>

                      <v-row column>
                        <v-col
                          cols="12"
                          class="instruction-form-container"
                          v-for="(item, index) in instructionContainer"
                          :key="index"
                        >
                            <v-row wrap justify-start>
                              <v-col align-self-center class="shrink">
                                <v-checkbox
                                  :input-value="selectedInstructions.includes(item.id)"
                                  v-on:change="addOrRemoveSelectedInstruction($event, item.id)"
                                  hide-details
                                />
                              </v-col>
                              <v-col>
                                <InstructionForm
                                  :index="index"
                                  :editedItem="item"
                                  v-on:remove-instruction="removeInstruction"
                                  :offsetCanBeSetted="offsetCanBeSetted"
                                  :optiAuto="!!editedItem.opti_auto"
                                  :ottoMinViz="!!editedItem.otto_min_viz"
                                  :useSurcouche="!!editedItem.use_opti_ratio_surcouche"
                                  :keepMinViz="Boolean(editedItem.keep_min_viz)"
                                  :isDislayErrorMessageOptiRatio="isDislayErrorMessageOptiRatio"
                                  :overwriteViewability="editedItem.overwrite_viewability"
                                  :kpiToOptimize="editedItem.true_KPI_to_optimize"
                                  v-on:duplicate-instruction="duplicateInstruction"
                                  v-on:open-strat="emitOpenStrat"
                                  ref="instructionFormComponents"
                                  :key="getKeyInstruForm(item) + keyInstruForm"
                                  :keystoneMemberSetting="keystoneMemberSetting"
                                  :strat-info="stratInfo"
                                >
                                </InstructionForm>
                              </v-col>
                            </v-row>
                        </v-col>

                      </v-row>
                      <v-row wrap>
                        <div class="cursor-pointer" @click="addInstruction()">
                          <v-btn small fab color="#3379C7" class="ma-2"><v-icon color="white">add</v-icon></v-btn>
                          <span>Add new instruction</span>
                        </div>
                      </v-row>

                      <!-- EXPLODE ERROR MESSAGE -->

                      <v-alert type="info" v-model="noInstruStrat">
                        No instructions strat for these instructions
                      </v-alert>

                      <v-alert type="error" v-model="errorCallApi">
                        Error when explode process. Please contact an administrator.
                      </v-alert>
                    </template>

                    <!-- INSTRUCTION SUB VIEW: SURCOUCHE FORCE RATIO -->
                    <template v-else-if="currentInstructionSubView === 'ins-sub-view-1'" class="pt-4">
                      <IOSurcoucheForceRatio
                        :io="editedItem"
                        :dsp="dsp"
                      />
                    </template>

                  </v-tab-item>

                  <!-- LOGS TAB -->
                  <v-tab-item eager :value="'tab-3'" >
                    <StatusLogsComponent
                      :externalId="editedItemIo"
                      :brief="brief"
                      :dsp="dsp"
                      ref="statusLogsComponentRef"
                    />
                  </v-tab-item>
                  <v-tab-item eager :value="'tab-4'">
                    <InstruNotesComponent
                      :insertion-order-id="editedItemIo"
                      :dsp="dsp"
                      :count-note.sync="countNotes"
                    >
                    </InstruNotesComponent>
                  </v-tab-item>
                </v-container>
              </v-tabs-items>
            </v-tabs>
          </div>
        </v-form>
        <WarningDialog
          v-model="warningDialog"
          v-on:warning-checked="emitSave(statusToSave)"
          :warningList="errorWarningChecker"
          :notValidOffset="notValidOffset"
        >
        </WarningDialog>
        <!-- confirm dialog -->
        <SimplePrompt
          v-model="confirmOption.dialog"
          :loading="confirmOption.loading"
          :approve="confirmOption.approveText"
          :cancel="confirmOption.cancelText"
          @approve="confirmOption.approveFunc()"
          @cancel="confirmOption.cancelFunc()"
          :text="confirmOption.text"
          approve-color="green"
        >
        </SimplePrompt>
    </v-card>
</template>

<script>
import { ioBriefMixins } from '../../../mixins/instructions.ts'
import { generalMixin } from '../../../mixins/generalMixin'
import { rulesMixin } from '../../../mixins/rulesMixin'
import { activateMixin } from '../../../mixins/activateMixin'
import { InstructionForm, WarningDialog } from './FormFragments'
import { MatchingParser, ReverseMatchingParser } from '../../../../utils/matchingParser.js'
import { setTimeout } from 'timers'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import OptimizeRulesChecker from '../../../../utils/BusinessRulesChecker/OptimizeRulesChecker'
import { BriefRulesChecker } from '../../../../utils/BusinessRulesChecker/BriefRulesChecker'
import { WarningsChecker } from '../../../../utils/BusinessRulesChecker/WarningsChecker'
import * as dspUrlGenerator from '../../../../utils/dspUrlGeneratorFromInstruction'
import { explodeInstructionsMixin } from '../../../mixins/explodeInstructionMixin'
import ExplodeDialog from './FormFragments/ExplodeDialog'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import TraderViewComponent from '@/components/TableComponents/Form/FormTabs/TraderViewComponent'
import InstructionGetterSetter from '../../../models/Overlay/InstructionGetterSetter'
import BriefPrefiller from '../../../../utils/BriefPrefiller/BriefPrefiller'
import { getTraderBriefMixin } from '@/mixins/getTraderBriefMixin'
import {
  warningPrefillMessage
} from '../../../../utils/BriefPrefiller/PrefillerWarning'
import BriefModel from '@/models/Briefs/BriefModel'
import AcknowledgementsForm from '@/components/TableComponents/Form/FormFragments/AcknowledgementsForm'
import InstruNotesComponent from '@/components/TableComponents/Form/FormTabs/InstruNotesComponent'
import BulkEditDialog from './FormFragments/BulkEditDialog'
import AlertErrorDialog from '../../Common/AlertErrorDialog.vue'
import StatusLogsComponent from './FormFragments/StatusLogs.vue'
import IOBriefTabForm from './IOFormFragments/IOBriefTabForm.vue'
import IOFormHeader from './IOFormFragments/IOFormHeader.vue'
import { checkInStratsNewKpiCpaNotTooHigh, getCheckStratsErrorMessage } from '../../../../utils/CheckStrats/CheckStrats'
import { MarkupModuleModel } from '@/models/Keystone_v2/MarkupModuleModel'
import CheckboxButton from './FormFragments/CheckboxButton.vue'
import { KpiValue } from '../../../../types/brief_enum'
import NewFeatureReferenceModel from '@/models/Keystone_v2/NewFeatureReferenceModel'
import { isActivatedCheckPreMep } from '../../../../config/featureRefHelper'
import { $BEESWAX, $YOUTUBE } from '../../../../config/dspConfig'
import { getNewIoField, recomposeAdvertiserIdBeeswax, recomposeIoIdBeeswax } from '../../../../utils/instructionsUtils'
import { ThirdPartyKpiHelper } from '../../../../utils/ThirdPartyKpi/thirdPartyKpiHelper'
import _ from 'lodash'
import { isBetaDsp } from '../../../../types/instruction_type_helper'
import { InsertionOrderProcessor } from '../../../../utils/Processor/InsertionOrderProcessor'
import SimplePrompt from '../../Common/SimplePrompt.vue'
import { roundNum } from '../../../../utils/commonUtils'
import IOSurcoucheForceRatio from './IOFormFragments/IOSurcoucheForceRatio'

const EXPLODE_DIALOG_STEP = {
  OPTI_AUTO: 'opti_auto',
  OTTO_MIN_VIZ: 'otto_min_viz'
}

const NAME_FOR_TAB = {
  'tab-0': 'Trader',
  'tab-1': 'Brief',
  'tab-2': 'Instructions',
  'tab-3': 'Logs',
  'tab-4': 'Notes'
}

const INSTRUCTIONS_SUB_VIEWS = {
  'ins-sub-view-0': 'instructrions_form_tab', // default
  'ins-sub-view-1': 'surcouche_force_ratio_tab'
}

export default {
  name: 'IOForm',
  props: {
    formTitle: {
      type: String,
      required: true
    },
    /**
     * @type {Instruction[]}
     */
    editedInstructions: {
      type: Array,
      default: function () {
        console.log('EDITED INSTRUCTIONS SET TO DEFAULT VALUE')
        let defaultInstruction = this.$commonUtils.deepCopy(this.$store.getters.getDefaultItem)
        return [defaultInstruction]
      }
    },
    dataFormGroupKey: {
      type: Object,
      default: function () {
        return {}
      }
    },
    isEditForm: {
      type: Boolean
    }
  },
  mixins: [
    ioBriefMixins,
    activateMixin,
    generalMixin,
    rulesMixin,
    explodeInstructionsMixin,
    getTraderBriefMixin
  ],
  components: {
    InstruNotesComponent,
    TraderViewComponent,
    ExplodeDialog,
    InstructionForm,
    WarningDialog,
    AcknowledgementsForm,
    BulkEditDialog,
    AlertErrorDialog,
    StatusLogsComponent,
    IOBriefTabForm,
    IOFormHeader,
    CheckboxButton,
    SimplePrompt,
    IOSurcoucheForceRatio
  },
  data: function () {
    return {
      /**
       * @type {keyof typeof NAME_FOR_TAB}
       */
      currentTab: 'tab-1',
      /**
       * @type {keyof typeof INSTRUCTIONS_SUB_TABS}
       */
      currentInstructionSubView: 'ins-sub-view-0',
      defaultInstructionSubView: 'ins-sub-view-0', // default
      forceRatioSurcoucheViewSwitch: true,
      formIsValidated: false,
      /**
       * @type {Instruction[]}
       */
      instructionContainer: [],
      showConstraintToRespect: false,
      showAdvancedStrategy: false,
      closeMessage: 'Item values have been updated but you havent saved the changes. Are you sur you want to close this window ?',
      invalidInputs: {
        tab1: 0,
        tab2: 0,
        tab3: 0
      },
      matchingParserLi: new MatchingParser(),
      reverseMatchingParser: new ReverseMatchingParser(),
      tabFixed: false,
      untie: {
        label: 'untie "true_KPI_to_optimize" from "Constraints to respect"',
        value: true
      },
      /**
       * form style options
       */
      isKpiToOptimizeDisabled: false,
      // showNoDelivery: false,
      /**
       * errors from rules checker
       */
      optimizeRulesChecker: new OptimizeRulesChecker(),
      briefRulesChecker: new BriefRulesChecker(),
      warningsChecker: new WarningsChecker(),
      errorOptimizeRulesChecker: [],
      errorBriefRulesChecker: {
        errors: [],
        names: []
      },
      errorWarningChecker: {
        errors: [],
        names: []
      },
      /**
       * warning values
       */
      warningDialog: false,
      notValidOffset: [],
      isProcessingExplode: false,
      dialogExplode: false,
      explodeDialogStep: EXPLODE_DIALOG_STEP.OPTI_AUTO,
      isLoadingSurcoucheConfig: false,
      isLoadingKeystoneSetting: false,
      keystoneMemberSetting: new MemberSettingModel(),
      /**
       * @type {InstructionGetterSetter | null}
       */
      insGetterSetter: new InstructionGetterSetter(this.editedItem, this.dsp),
      /**
       * @readonly
       * @type {string}
       */
      linkInterface5000ThirdPartyConv: 'http://interface.sbds.io:5000/third_party_conv/',
      isInit: false,
      messageBriefNotConfirm: warningPrefillMessage,
      /*
      * Save button
      */
      openMenu: false,
      /*
      * Otto min viz
      */
      dialogAlertError: false,
      ottoErrorText: '<p>You cannot modify this field because <span style="font-weight: bold; text-transform: uppercase;">otto min viz</span> is activated.<br/>Please deactivate it (in the <span style="font-weight: bold; text-transform: uppercase;">instructions</span> tab) before modifying this field.</p>',
      /*
      * Bulk Edit
      */
      dialogBulkEdit: false,
      selectedInstructions: [],
      allInstructionsSelected: false,
      allInstructionsIndeterminate: false,
      countNotes: 0,
      /**
       * @type {StatusInstruction | null}
       * used with the WarningDialog, for passing the statusToSave when the warning dialog is used
       */
      statusToSave: null,
      /**
       * @type {InstructionStrat[]}
       */
      stratInfo: [],
      loadingOverview: false,
      keyInstruForm: false,
      /**
       * remove scibids
       */
      eventsOfIo: [],
      confirmOption: {
        dialog: false,
        loading: false,
        approveText: '',
        cancelText: '',
        text: '',
        approveFunc: () => {},
        cancelFunc: () => {}
      },
      /**
       * Forced Ratio
       */
      sfrAuthorizedDsps: []
    }
  },
  created: function () {
  },
  mounted: function () {
    this.$nextTick(async () => {
      if ((this.dsp && this.editedItemIo) || this.isInstruCreatedViaDataform || isBetaDsp(this.dsp)) { // for avoid unnecessary init
        await this.initForm()
        if (this.dsp === this.$DBM && (this.editedItem.id == null || this.editedItem.id === '')) {
          this.setUseCustomAlgoDefaultValue()
        }
        this.$nextTick(() => {
          if (this.$refs.instructionform && 'resetValidation' in this.$refs.instructionform) {
            this.$refs.instructionform.resetValidation()
          } else {
            console.warn('Can not reset validation')
          }
        })
      }
    })
  },
  methods: {
    ...mapMutations(['clearInstructionsInWarning']),
    ...mapActions(['closeDataFormProcess']),
    emitClose () {
      if (this.itemHasBeenUpdated()) {
        if (confirm(this.closeMessage)) {
          this.closeProcess()
        }
      } else {
        this.closeProcess()
      }
    },
    closeProcess () {
      this.$emit('close')
      this.resetValues()
    },
    /**
     * @returns {boolean} is the item in form changed ?
     */
    itemHasBeenUpdated () {
      return this.insGetterSetter.hasBeenUpdated()
    },
    /**
     * @param insertionOrderId {number|string}
     * @param instructionId {number|null}
     */
    emitOpenStrat (insertionOrderId, instructionId = null) {
      this.$emit('open-strat', insertionOrderId, instructionId)
    },
    formValidation () {
      if (!this.formIsValidated) {
        this.$refs.instructionform.validate()
        this.displayBadgesIfErrors()
        return false
      }

      return true
    },
    saveNormal () {
      if (!this.formValidation()) {
        return
      }
      if (!this.acknowledgmentsAreAllOk) {
        return
      }
      this.saveAsStatus(this.$VALIDATED)
    },
    saveAsDraft () {
      if (!this.formValidation()) {
        return
      }
      this.saveAsStatus(this.$DRAFT)
    },
    saveAsReview () {
      if (!this.formValidation()) {
        return
      }
      if (!this.acknowledgmentsAreAllOk) {
        return
      }
      this.saveAsStatus(this.$TO_VALIDATE)
    },
    saveAsValidated () {
      if (!this.formValidation()) {
        return
      }
      if (!this.acknowledgmentsAreAllOk) {
        return
      }
      this.insGetterSetter.retry = true
      this.saveAsStatus(this.$VALIDATED)
    },
    /**
     * Save the instructions as status <newStatus>
     * Before save, check some warning and display the warning dialog if necessary
     * @param newStatus {StatusInstruction}
     */
    saveAsStatus (newStatus) {
      if (this.checkWarnings()) {
        // here, we save the status to save for passing it in the warning dialog
        this.statusToSave = newStatus
        this.warningDialog = true
      } else {
        this.warningDialog = false
        this.emitSave(newStatus)
      }
    },
    markupModulePreSaveProcess () {
      if (this.editedItem.markup_management_is_inherited_from_seat == null || this.editedItem.markup_management_is_inherited_from_seat === false) {
        this.editedItem.markup_management_is_inherited_from_seat = false
        this.editedItem.markup_module = this.insGetterSetter.markupModule
      } else {
        this.editedItem.markup_management_is_inherited_from_seat = true
        this.editedItem.markup_module = {}
      }
    },
    revenueTypePreSaveProcess () {
      if (this.editedItem.revenue_type !== 'rev_cpm') {
        this.insGetterSetter.expectedMargin = null
      }
    },
    customProdManagementPreSaveProcess () {
      if (this.editedItem.custom_prod_management_is_inherited_from_seat == null || this.editedItem.custom_prod_management_is_inherited_from_seat === false) {
        this.editedItem.custom_prod_management_is_inherited_from_seat = false
        this.editedItem.custom_prod_management_module = this.$refs['io-brief-tab-form'].getConvertedCustomProdMgmtModule()
      } else {
        this.editedItem.custom_prod_management_is_inherited_from_seat = true
        this.editedItem.custom_prod_management_module = {}
      }
    },
    preSaveProcess () {
      if (this.activateMarkupModule) {
        this.markupModulePreSaveProcess()
      }
      this.revenueTypePreSaveProcess()

      if (this.activateCustomProdMgmt) {
        this.customProdManagementPreSaveProcess()
      }
    },
    /**
     * @param statusToSave {StatusInstruction}
     */
    emitSave (statusToSave) {
      if (this.formIsValidated) {
        this.insGetterSetter.status = statusToSave
        this.preSaveProcess()
        const baseline = this.$store.getters.getCurrentBaseline
        this.$emit('save', this.acknowledgments, baseline)
        this.resetValues()
      } else {
        this.$refs.instructionform.validate()
        this.displayBadgesIfErrors()
        this.statusToSave = null
      }
    },
    checkWarnings () {
      this.errorWarningChecker = this.warningsChecker.checkWarnings(this.editedItem, this.instructionContainer, this.dsp)
      this.notValidOffset = []
      this.warningsAllBut = []

      if (this.errorWarningChecker.names.indexOf('warningKpiToOptimizeOffset') !== -1) {
        this.notValidOffset = this.warningsChecker.getLastNotValidOffset()
      }

      let warningsOptimizeRulesChecker = this.optimizeRulesChecker.checkWarnings(this.instructionContainer.filter(item => item.is_active), this.dsp)

      if (warningsOptimizeRulesChecker.errors.length !== 0) {
        this.errorWarningChecker.errors = [...this.errorWarningChecker.errors, ...warningsOptimizeRulesChecker.errors]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, ...warningsOptimizeRulesChecker.names]
      }

      if (!this.checkerBlock.isGroupKeyValid) {
        this.errorWarningChecker.errors = [...this.errorWarningChecker.errors, `<span>${this.checkerBlock.errorMessageGroupKey}</span>`]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, 'warningGroupKeyNotValid']
      }

      if (!this.checkerBlock.isClientIdInDspConfig) {
        this.errorWarningChecker.errors = [...this.errorWarningChecker.errors, `<span>The client id is not set in dsp config.</span>`]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, 'warningClientIdNotInDspConfig']
      }

      if (!this.checkerBlock.isConfigStatusComplete) {
        this.errorWarningChecker.errors = [...this.errorWarningChecker.errors, `<span>Data input is not activated for this member, your item won't be optimized.</span>`]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, 'warningConfigStatusNotComplete']
      }

      const checkStratsResult = checkInStratsNewKpiCpaNotTooHigh(
        this.editedItem.opti_auto, this.instructionContainer, this.stratInfo, this.dsp, 'warning')

      if (!checkStratsResult.check) {
        this.errorWarningChecker.errors = [...this.errorWarningChecker.errors,
          getCheckStratsErrorMessage(checkStratsResult.multiplier, 'warning')
        ]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, 'warningMaxAIncreaseTooHigh']
      }

      // Add warning if a debugger uer changed "use_cstom_algo" on a dbm instruction with revenue_type == 'cost_plus'
      if (this.dsp === this.$DBM && this.editedItem.use_custom_algo === false && this.$store.getters.getUserStatus.isDebugger === true) {
        this.errorWarningChecker.errors = [...this.errorWarningChecker.errors, `<span>You have activated Bid Multipliers for this IO. Make sure the setup is correct before saving.</span>`]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, 'warningUseCustomAlgoDeactivatedAsDebugger']
      }

      const newFeaturesRef = this.$store.getters.getCurrentNewFeatureReferences

      // Add warning if a user save an io previously in VALIDATED but with a check_premep no more Valid.
      const wasStatusValidated = this.insGetterSetter.status === this.$VALIDATED
      if (isActivatedCheckPreMep(newFeaturesRef, this.dsp) && wasStatusValidated && !this.$store.getters.isCheckPreMepValid) {
        this.errorWarningChecker.errors = [
          ...this.errorWarningChecker.errors,
          `<span>This io does no longer pass the <strong>check_premep</strong>. This can severely decrease performance,
            please click on the <em>"Blocking"</em> button to know the blocking parameters</span>`
        ]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, 'warningValidatedButCheckPreMepNotValid']
      }

      if (isActivatedCheckPreMep(newFeaturesRef, this.dsp) && this.checkPreMepResult && this.checkPreMepResult.check_setup_state === 'blocking_editable') {
        this.errorWarningChecker.errors = [
          ...this.errorWarningChecker.errors,
          `<span>The IO setup seems to prevent the AI from pushing any model.
              Please make sure all blocking and editable properties have been fixed before validating.</span>`
        ]
        this.errorWarningChecker.names = [...this.errorWarningChecker.names, 'warningCheckPreMepBlockingEditable']
      }
      return this.errorWarningChecker.names.length !== 0
    },
    /**
     * Search for all the errors in the inputs of the form and search for a "tab" attribute in the inputs
     * or in the parent of the input recursively until we find it.
     * If a "tab" attribute is find, will search for a "formPart" attribute
     * in the component where the "tab" attribute is found. If a "formPart" attribute is found,
     * will add it to the formPartWithError array in the vuex store.
     */
    displayBadgesIfErrors () {
      this.resetInvalidInput()
      this.$store.commit('setFormPartWithError', [])
      let formPartWithError = []

      if (this.$refs.instructionform !== undefined) {
        let formInputs = this.$refs.instructionform.inputs

        for (let i in formInputs) {
          if (!formInputs[i].valid || formInputs[i].errorMessages.length !== 0) {
            let tab = formInputs[i].$attrs.tab
            let formPart = formInputs[i].$attrs.formPart
            if (!tab) {
              // if no tab is defined, we search recursively in the parent
              // until we find the tab attribute
              let parent = formInputs[i].$parent
              let maxDepth = 10
              while (parent && !tab && maxDepth > 0) {
                tab = parent.$attrs.tab
                formPart = parent.$attrs.formPart
                parent = parent.$parent
                maxDepth--

                // if no formPart but a tab element is find, will search recursively in the parent until
                // we find the formPart attribute or we attain the maxDepthFormPart
                if (tab && !formPart) {
                  let maxDepthFormPart = 10
                  let parentFormPart = parent

                  while (!formPart && maxDepthFormPart > 0) {
                    formPart = parentFormPart.$attrs.formPart
                    parentFormPart = parentFormPart.$parent
                    maxDepthFormPart--
                  }
                }
              }

              if (!tab) {
                console.warn('No tab found for input', formInputs[i])
                continue
              }
            }
            if (tab) {
              this.$set(this.invalidInputs, tab, this.invalidInputs[tab] + 1)

              if (formPart) {
                formPartWithError.push(formPart)
              }
            }
          }
        }
      }
      this.$store.commit('setFormPartWithError', formPartWithError)
    },
    launchTimeOutBadge () {
      _.debounce(function () {
        this.displayBadgesIfErrors()
      }, 600)
    },
    /**
     * reset the constraint check box to false
     */
    resetConstraints () {
      if (this.$refs.constraintsBlock) {
        this.$refs.constraintsBlock.resetConstraints()
      }
    },
    resetInvalidInput () {
      this.invalidInputs = {
        tab1: 0,
        tab2: 0,
        tab3: 0
      }
    },
    initCurrentTab () {
      this.currentTab = 'tab-1'
    },
    initcurrentInstructionSubView () {
      this.currentInstructionSubView = this.defaultInstructionSubView
    },
    async initForm () {
      this.isInit = false
      this.initCurrentTab()
      this.initcurrentInstructionSubView()

      this.insGetterSetter = new InstructionGetterSetter(this.editedItem, this.dsp)

      if (this.isInstruCreatedViaDataform) {
        this.getDataformInfo()
      }

      await this.getTraderBrief(this.insGetterSetter.clientIdPerDsp, this.editedItemIo, this.dsp)

      this.$store.commit('setCurrentTraderBrief', this.brief)

      if (this.brief && this.brief.id) {
        await this.getAcknowledgmentsOfBriefId(this.brief.id)
      } else if (this.isInstruCreatedViaDataform && this.brief && this.brief.isNoteBrief() && typeof this.brief.notes[0].content === 'string') {
        this.acknowledgments = [{
          field_name: 'General Comment',
          comments: this.brief.notes[0].content,
          is_ok: false
        }]
      }

      if (this.isInstruCreatedViaDataform) {
        if (this.isTraderBriefAvailable()) {
          this.prefillInstructionWithTraderBrief(this.brief, this.funnel)
          // insGetterSetter is recreated with the prefilled editedItem
          this.insGetterSetter = new InstructionGetterSetter(this.editedItem, this.dsp)
        }
      }

      this.$store.commit('setStartStatusInstruction', this.editedItem.status)
      await this.loadPivotVariables()
      await this.loadOverview()
      await this.loadSurcoucheConfig()
      await this.loadKeystoneConfig()
      await this.loadNewFeatureReferences()
      await this.loadStratsInfo()
      await this.loadOverviewAttributes()
      await this.loadCheckPreMep()
      await this.loadBaselines()
      await this.loadThirdPartyGenData()
      await this.loadColdStartAvailability()
      await this.loadAvailableKpis()

      this.instructionContainer = this.editedInstructions

      // added cause if we do not wait for next tick instructionContainer
      // may not be set for Vue when executing setDataForm
      this.$nextTick(() => {
        this.setValueDataForm()
      })
      if (this.instructionContainer.length === 0) {
        this.addInstruction()
      }

      this.sortInstructionContainer()

      // show advanced field if they aren't in default value
      this.showConstraintToRespect = !this.isConstraintToRespectInDefault()

      this.setUntieValue()
      this.setExecutorIfUndefined()
      this.setOptiAutoIfUndefined()
      this.setDefaultValueCampaignResult()
      this.setDefaultValueMeasurement()
      this.setDefaultValueOttoMinViz()
      this.setDefaultValueOverwriteFrequencyTarget()

      setTimeout(() => {
        // some values of the markupModule are set to default value in some case.
        // for avoid the pop-up item-updated to being triggered when closing the form without update of the editedItem
        // we set the markupmodule with the getObjectForApi before saving the original
        this.insGetterSetter.markupModule = new MarkupModuleModel(this.insGetterSetter.markupModule).getObjectForApi()
        this.insGetterSetter.saveOriginal()
      }, 500)

      // Retrieve authorized keys per DSP for Forced Ratio
      // this.setSfrAuthorizedDsps()
      this.isInit = true
    },
    async loadStratsInfo () {
      if (this.editedItemIo != null) {
        const response = await this.$apiCaller.getInstructionsStratWithInsertionOrderid(this.editedItemIo, 'live', this.dsp, null, null, 250)
        if (this.$apiCaller.isResponseError(response)) {
          this.$store.commit('setErrorMessageWithResponse', response)
        } else {
          this.stratInfo = response.data
        }
      }
    },
    /**
     * reset values of the form and delete element from query
     */
    resetValues () {
      this.currentTab = 'tab-1'
      this.currentInstructionSubView = 'ins-sub-view-0'
      this.original = {}
      this.instructionContainer = []

      this.$refs.instructionform.resetValidation()
      // reset show advanced value
      this.showConstraintToRespect = false

      if (this.$refs.advancedAlgoStrategy !== undefined) {
        this.$refs.advancedAlgoStrategy.resetShow()
      }

      this.resetConstraints()
      this.resetInvalidInput()

      if (this.$refs.conversionFunnel !== undefined) {
        this.$refs.conversionFunnel.resetAll()
      }
      this.closeDataFormProcess({ $route: this.$route, $router: this.$router })
      this.clearInstructionsInWarning()
      this.resetErrorExplode()
      this.statusToSave = null
    },
    /**
     * called when ConstraintsForm emit updateConstraints
     * @param computed {NonMethodKeys<InsGetterSetter>} the computed attribute to update with value
     * @param value {Number} value
     * @param noConstraint {Boolean} Is noConstraint checked ?
     */
    updateConstraints (computed, value, noConstraint) {
      if (!computed) {
        return
      }
      this.insGetterSetter[computed] = value
    },
    isTraderBriefAvailable () {
      return this.brief && this.brief instanceof BriefModel && !this.brief.isNoteBrief()
    },
    displayAcknowledgment () {
      return this.brief && this.brief instanceof BriefModel
    },
    /**
     * add a default instruction in the instruction container
     */
    addInstruction () {
      let defaultInstruction = this.$commonUtils.deepCopy(this.$store.getters.getDefaultItem)
      this.addFormKey(defaultInstruction)
      this.instructionContainer.push(defaultInstruction)
      this.keyInstruForm = !this.keyInstruForm
    },
    addFormKey (instruction) {
      instruction.formKey = Math.random()
    },
    getKeyInstruForm (item) {
      return item.id || item.formKey
    },
    /**
     * @param item {Instruction} the item to remove
     * remove the item to the instruction container
     */
    removeInstruction (item) {
      const index = this.instructionContainer.indexOf(item)
      this.instructionContainer.splice(index, 1)
      this.keyInstruForm = !this.keyInstruForm
    },
    toggleOptiAuto () {
      this.editedItem.opti_auto = !this.editedItem.opti_auto
      if (!this.editedItem.opti_auto) {
        this.explodeDialogStep = EXPLODE_DIALOG_STEP.OPTI_AUTO
        this.dialogExplode = true
      }
    },
    toggleOttoMinViz () {
      this.insGetterSetter.ottoMinViz = !this.insGetterSetter.ottoMinViz
      if (!this.editedItem.otto_min_viz) {
        this.explodeDialogStep = EXPLODE_DIALOG_STEP.OTTO_MIN_VIZ
        this.dialogExplode = true
      }
    },
    toggleUseCustomAlgo () {
      this.editedItem.use_custom_algo = !this.editedItem.use_custom_algo
    },
    toggleReactivateOtto () {
      this.insGetterSetter.reactivateOtto = !this.insGetterSetter.reactivateOtto
    },
    /**
     * change the value action in all the instruction in instructionContainer
     * @param fieldValue {KeysOf<import('../../../../types/instruction_type').DspInstruction>} the field to change
     * @param action {any} the value to change for all the instruction
     */
    changeAllInstructionFieldTo (fieldValue, action) {
      for (let i in this.instructionContainer) {
        this.$set(this.instructionContainer[i], fieldValue, action)
      }
    },
    rulesBrief () {
      if ([this.$MEDIAMATH, this.$APPNEXUS, this.$DBM, this.$THETRADEDESK, this.$BEESWAX].indexOf(this.dsp) !== -1) {
        return this.errorBriefRulesChecker.errors !== undefined ? this.errorBriefRulesChecker.errors : []
      }

      return []
    },
    rulesInstructionsContainer () {
      return this.errorOptimizeRulesChecker
    },
    /**
     * @returns {Boolean}
     */
    isConstraintToRespectInDefault () {
      return this.$isNullOrUndefined(this.insGetterSetter.proxyTargetForOptimizedKpi) || (typeof this.insGetterSetter.proxyTargetForOptimizedKpi === 'string' && this.insGetterSetter.proxyTargetForOptimizedKpi === '')
    },
    /**
     * add a copy to the instruction container of the instruction with the index 'index',
     * without copying the group name, optimize_lineitems and optimize campaigns (setted to default value)
     * @param index {Number}
     */
    duplicateInstruction (index) {
      if (!this.$commonUtils.isNumeric(index)) {
        throw Error('index must be a numeric value')
      }

      let toRemoveOnDuplicateCommon = ['id', 'insertion_date', 'group_name']

      let toRemoveOnDuplicate = {
        [this.$APPNEXUS]: [this.$OPTIMIZE_LINEITEMS, this.$OPTIMIZE_CAMPAIGNS, ...toRemoveOnDuplicateCommon],
        [this.$MEDIAMATH]: [this.$OPTIMIZE_STRATEGIES, ...toRemoveOnDuplicateCommon],
        [this.$THETRADEDESK]: [this.$OPTIMIZE_ADGROUPS, ...toRemoveOnDuplicateCommon],
        [this.$DBM]: [this.$OPTIMIZE_LINEITEMS, ...toRemoveOnDuplicateCommon],
        [this.$BEESWAX]: [this.$OPTIMIZE_LINEITEMS, ...toRemoveOnDuplicateCommon],
        [this.$YOUTUBE]: [this.$OPTIMIZE_LINEITEMS, ...toRemoveOnDuplicateCommon],
        [this.$FACEBOOK]: [this.$OPTIMIZE_ADSETS, ...toRemoveOnDuplicateCommon]
      }

      let defaultItem = this.$store.getters.getDefaultItem

      if (this.$inArray(this.instructionContainer, index)) {
        let toDuplicate = this.$commonUtils.deepCopy(this.instructionContainer[index])

        if (Object.keys(toRemoveOnDuplicate).indexOf(this.dsp) === -1) {
          throw new Error(`${this.dsp} is not in toRemoveOnDuplicate Array. This dsp must be added in this array`)
        }

        // set the field in to remove on duplicate to her default value
        for (let i in toRemoveOnDuplicate[this.dsp]) {
          let field = toRemoveOnDuplicate[this.dsp][i]
          if (this.$commonUtils.issetInObject(toDuplicate, field)) {
            toDuplicate[field] = defaultItem[field]
          }
        }

        this.addFormKey(toDuplicate)

        // add the copying item to the instructionContainer
        this.instructionContainer.push(toDuplicate)
      } else {
        console.log('The index ' + index + ' isn\'t in the instructionContainer')
      }
    },
    setExecutorIfUndefined () {
      if (this.$store.getters.getFormStatus === this.$NEW) {
        return
      }

      if (this.activateExecutor === false) {
        return
      }

      if (this.$isNullOrUndefined(this.editedItem.executor_version)) {
        this.insGetterSetter.executorVersion = 1
      }
    },
    setOptiAutoIfUndefined () {
      if (this.$store.getters.getFormStatus === this.$NEW) {
        return
      }

      if (this.$isNullOrUndefined(this.editedItem.opti_auto)) {
        this.editedItem.opti_auto = false
      }
    },
    //
    setUntieValue () {
      let formStatus = this.$store.getters.getFormStatus
      const thirdPartyHelper = new ThirdPartyKpiHelper()
      if (this.insGetterSetter.proxyTargetForOptimizedKpi === this.insGetterSetter.proxyKpiToOptimize ||
        formStatus === this.$NEW ||
        thirdPartyHelper.isKpiSecondLvl(this.insGetterSetter.trueKpiToOptimize)
      ) {
        this.$set(this.untie, 'value', false)
      }
    },
    setDefaultValueCampaignResult () {
      let formStatus = this.$store.getters.getFormStatus
      const defaultValue = 1.3
      if (formStatus === this.$NEW && this.dsp === this.$FACEBOOK) {
        if (!this.$issetAndNotEmpty(this.insGetterSetter.campaignMaxMult)) {
          this.insGetterSetter.campaignMaxMult = defaultValue
        }

        if (!this.$issetAndNotEmpty(this.insGetterSetter.campaignMaxDiv)) {
          this.insGetterSetter.campaignMaxDiv = defaultValue
        }
      }
    },
    setDefaultValueMeasurement () {
      let formStatus = this.$store.getters.getFormStatus
      const defaultValue = this.dsp
      if (formStatus === this.$NEW && !this.insGetterSetter.trueConvMeasurementTool) {
        this.insGetterSetter.trueConvMeasurementTool = defaultValue
      }
    },
    getOvervriteViewabilityValueForDsp () {
      switch (this.dsp) {
        case this.$APPNEXUS:
          return this.editedItem.remove_min_viz
        case this.$DBM:
          return !this.editedItem.keep_min_viz
        case this.$THETRADEDESK:
          return this.editedItem.overwrite_viewability
      }
    },
    setDefaultValueOttoMinViz () {
      let formStatus = this.$store.getters.getFormStatus
      if (formStatus === this.$NEW) {
        if (this.allowOttoMinViz) {
          this.insGetterSetter.optiAuto = true
          this.insGetterSetter.ottoMinViz = true
        }
      }
    },
    setDefaultValueOverwriteFrequencyTarget () {
      if (this.insGetterSetter.trueKpiToOptimize !== 'reach_and_frequency') {
        this.insGetterSetter.overwriteFrequencyTarget = false
      }
    },
    getDspUrl () {
      const overview = this.$store.getters.getCurrentOverview
      if (!this.displayDspUrl) {
        return ''
      }
      let url = null
      if (this.dsp === this.$DBM && overview !== null) {
        url = overview.url
      }
      return dspUrlGenerator.getUrlPerDsp(this.editedItem, this.dsp, url)
    },
    clickOnRetry () {
      this.$plausibleHelper.doActionAndTrackEvent(
        () => this.toggleValue('retry'),
        'click_on_retry', { props: { value: !this.insGetterSetter.retry } })
    },
    clickOnFastRetry () {
      this.$plausibleHelper.doActionAndTrackEvent(
        () => this.toggleValue('fastRetry'),
        'click_on_fast_retry', { props: { value: !this.insGetterSetter.fastRetry } })
    },
    /**
     * @param {BooleanKeys<import('../../../../types/instruction_type').DspInstruction>} key
     */
    toggleValue (key) {
      this.insGetterSetter[key] = !this.insGetterSetter[key]
    },
    async explodeInstruStratProcess () {
      this.isProcessingExplode = true
      const instructionIds = this.editedInstructions.map(item => item.id)
      const optiAuto = this.editedItem.opti_auto
      const ottoMinViz = this.editedItem.otto_min_viz
      const newInstruOpti = await this.getExplodeInstructionStrat(
        instructionIds,
        this.dsp,
        optiAuto,
        ottoMinViz,
        this.explodeDialogStep
      )

      if (newInstruOpti && newInstruOpti.length !== 0) {
        // set old instruction to is_active = false
        this.changeAllInstructionFieldTo('is_active', false)

        for (let i in newInstruOpti) {
          this.addFormKey(newInstruOpti[i])
          this.instructionContainer.push(newInstruOpti[i])
        }
        this.sortInstructionContainer()
      }

      this.isProcessingExplode = false
      this.closeExplodeDialog()
    },
    closeExplodeDialog () {
      this.dialogExplode = false
    },
    sortInstructionContainer () {
      this.instructionContainer.sort((a, b) => {
        if (a.is_active && !b.is_active) {
          return -1
        } else if (!a.is_active && b.is_active) {
          return 1
        }
        return 0
      })
    },
    async loadSurcoucheConfig () {
      this.isLoadingSurcoucheConfig = true
      await this.$surcoucheConfig.refreshConfig()
      this.isLoadingSurcoucheConfig = false
    },
    async loadNewFeatureReferences () {
      if (!this.keystoneMemberSetting) {
        console.warn('keystoneMemberSetting should be loaded to load the new feature references ' +
          'enabled on the member.')
        return
      }
      const application = 'member'
      const dsp = this.dsp
      const memberId = this.keystoneMemberSetting.id
      const response = await this.$apiCaller.getNewFeatureReferencesForEntityWithDsp(memberId, application, dsp)
      const newFeatureRef = []

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling getNewFeaturesReference')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        response.data.forEach(feature => {
          newFeatureRef.push(new NewFeatureReferenceModel(feature))
        })
        this.$store.commit('setCurrentNewFeatureReferences', newFeatureRef)
      }
    },
    async loadOverviewAttributes () {
      const externalId = this.editedItemIo
      const dsp = this.dsp
      if (externalId && dsp) {
        const response = await this.$apiCaller.getOverviewAttributes(externalId, dsp)
        if (this.$apiCaller.isResponseError(response)) {
          console.warn('Error when calling overview attributes.')
          await this.$store.commit('setErrorMessageWithResponse', response)
        } else {
          const data = response.data
          if (data.length) {
            this.overviewAttributes = data[0]
          } else {
            console.warn(`Io with id ${externalId} has no overview attributes`)
          }
        }
      }
    },
    async loadCheckPreMep () {
      const newFeaturesRef = this.$store.getters.getCurrentNewFeatureReferences
      if (!isActivatedCheckPreMep(newFeaturesRef, this.dsp)) {
        console.warn('Check pre mep is not activated for this instruction.')
        return
      }
      console.warn('Load check premep...')
      this.checkPreMepResult = null
      const externalId = this.editedItemIo
      const subDsp = this.dsp
      if (externalId && subDsp) {
        const response = await this.$apiCaller.getCheckPreMepResult(externalId, subDsp)
        if (this.$apiCaller.isResponseError(response)) {
          console.warn('Error when calling check premep.')
          await this.$store.dispatch('setErrorMessageWithResponse', response)
        } else {
          const data = response.data
          if (Object.keys(data).length) {
            this.checkPreMepResult = data
          } else {
            console.warn(`Io with id ${externalId} has no check premep`)
          }
        }
      }
    },
    isDisabledCustomAlgo () {
      // rev_cpm ok; debugger ok; am ok if editing + only to activate it, not deactivate it OR kpi == CPM
      return !(
        this.insGetterSetter.revenueType === 'rev_cpm' ||
        this.insGetterSetter.trueKpiToOptimize === KpiValue.CPM ||
        this.$store.getters.getUserStatus.isDebugger ||
        (this.$store.getters.getUserStatus.isAm && this.editedItem.use_custom_algo === false)
      )
    },
    isUseOptiRatioSurcoucheDisabled () {
      const thirdPartyKpiHelper = new ThirdPartyKpiHelper()
      return thirdPartyKpiHelper.secondLvlKpi.includes(this.insGetterSetter.trueKpiToOptimize)
    },
    async loadKeystoneConfig () {
      this.isLoadingKeystoneSetting = true

      const data = {
        search: this.insGetterSetter.clientIdPerDsp,
        dsp: this.dsp
      }

      if (data.dsp === 'youtube') {
        data.dsp = 'dbm'
      }

      const result = await this.$apiCaller.getKeystoneMemberSettings(data)

      if (this.$apiCaller.isResponseError(result, true)) {
        console.warn('Error or call cancelled when searching Keystone member settings')
        if (!this.$apiCaller.isCancelError(result)) {
          this.$store.commit('setErrorMessageWithResponse', result)
        }
        this.keystoneMemberSetting = new MemberSettingModel()
      } else if (result.data.length === 0) {
        console.warn('Can not find keystone member setting related to this client_id')
        this.keystoneMemberSetting = new MemberSettingModel()
      } else {
        this.keystoneMemberSetting = new MemberSettingModel(result.data[0])
      }
      this.isLoadingKeystoneSetting = false
    },
    async loadThirdPartyGenData () {
      this.$store.commit('setCurrentEventsOfIo', [])
      const overview = this.$store.getters.getCurrentOverview
      if (!overview || !overview.id) {
        console.warn('Overview is not loaded yet.')
        return
      }
      const result = await this.$apiCaller.getEventsOfIo(overview.id)

      if (this.$apiCaller.isResponseError(result, true)) {
        console.warn('Error or call cancelled when searching third party gen data')
        if (!this.$apiCaller.isCancelError(result)) {
          this.$store.commit('setErrorMessageWithResponse', result)
        }
        this.$store.commit('setCurrentEventsOfIo', [])
      } else {
        this.$store.commit('setCurrentEventsOfIo', result.data)
      }
    },
    async loadCheckPreMepValidator () {
      const data = _.cloneDeep(this.editedItem)
      const newIoField = getNewIoField(this.dsp)

      // in case there is no insertion_order_id in the object
      if (newIoField && !(newIoField in data)) {
        data[newIoField] = this.editedItemIo
      }

      const insertionOrderProcessor = new InsertionOrderProcessor()

      const dataToCheck = []
      for (let i in this.editedInstructions) {
        dataToCheck.push(insertionOrderProcessor.fillInstructionsWithIOBrief(
          this.editedInstructions[i],
          this.editedItem,
          this.$dspConfig[this.dsp]
        ))
      }

      const response = await this.$apiCaller.getCheckPreMepInstructionsValidator(dataToCheck, this.dsp)

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling checkpremep Validator.')
      } else {
        const data = response.data
        let result = data.length ? data[0] : null
        this.$store.commit('setCheckValidatorResult', result)
      }
    },
    getDataformInfo () {
      if (this.dataFormGroupKey.group_key !== undefined) {
        this.insGetterSetter.advertiserIdPerDsp = this.dataFormGroupKey.group_key.advertiser_id

        if (this.dsp === this.$BEESWAX) {
          // allow the computed property to work well with beeswax
          this.editedItemIo = this.dataFormGroupKey.group_key.insertion_order_id.split('_')[1]
        } else {
          this.editedItemIo = this.dataFormGroupKey.group_key.insertion_order_id
        }
        this.insGetterSetter.clientIdPerDsp = this.dataFormGroupKey.group_key.client_id
      }
    },
    async loadOverview () {
      this.loadingOverview = true
      this.$store.commit('setCurrentOverview', null)

      let ioId = this.editedItemIo
      let adId = this.insGetterSetter.advertiserId
      let clientId = this.insGetterSetter.clientIdPerDsp

      let overview = null

      if (this.dsp === $BEESWAX) {
        ioId = recomposeIoIdBeeswax(clientId, ioId)
        adId = recomposeAdvertiserIdBeeswax(clientId, adId)
      }

      const result = await this.$apiCaller.getOverviewGroupKey(ioId, adId, clientId)

      if (this.$apiCaller.isResponseError(result, true)) {
        console.warn('Error when calling api or call cancel')
        if (!this.$apiCaller.isCancelError(result)) {
          this.$store.commit('setErrorMessageWithResponse', result)
        }
      } else {
        overview = result.data[0]
        this.$store.commit('setCurrentOverview', overview)
      }
      this.loadingOverview = false
    },
    async loadBaselines () {
      const currentOverview = this.$store.getters.getCurrentOverview
      this.$store.commit('setBaselines', [])
      this.$store.commit('setStartBaseline', null)
      if (currentOverview) {
        const overviewId = currentOverview.id
        const response = await this.$apiCaller.getBaseline(overviewId)

        if (this.$apiCaller.isResponseError(response)) {
          console.warn('Error when calling baseline.')
          await this.$store.commit('setErrorMessageWithResponse', response)
        } else {
          const data = response.data
          if (data.length) {
            this.$store.commit('setBaselines', data)

            // current baseline is the most recent one
            let currentBaseline = data.sort((a, b) => {
              return this.$commonUtils.sortDate(a.insertion_date, b.insertion_date)
            })[0]

            // if type is 'ab_test', we check if the ai_performance is 'to_be_defined'.
            // if no, that mean there is no current baseline.
            if (currentBaseline.baseline_type === 'ab_test') {
              if (currentBaseline.ai_performance !== 'to_be_defined') {
                console.warn(`Io with id ${overviewId} has no baseline with ai_performance = to_be_defined`)
                currentBaseline = null
              }
            }

            // set the value of current baseline if not null, else set a default value
            if (currentBaseline) {
              this.$store.commit('setStartBaseline', currentBaseline)
            } else {
              console.warn(`Io with id ${overviewId} has no baseline with ai_performance = to_be_defined`)
              const defaultBaseline = {
                ai_performance: 'to_be_defined',
                baseline_type: 'other',
                baseline_io: null,
                before_start_date: null,
                before_end_date: null,
                ab_test_start_date: null,
                ab_test_end_date: null
              }
              this.$store.commit('setStartBaseline', defaultBaseline)
            }
          } else {
            console.warn(`Io with id ${overviewId} has no baseline`)
            this.createDefaultBaseline()
          }
        }
      } else {
        console.warn('No current overview, so no baseline.')
        this.createDefaultBaseline()
      }
    },
    async loadPivotVariables () {
      this.$store.commit('setCurrentPivotVariables', [])
      const response = await this.$apiCaller.getPivotVariables(this.dsp)

      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling pivot variables.')
        await this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        const data = response.data
        if (data.length) {
          this.$store.commit('setCurrentPivotVariables', data)
        } else {
          console.warn(`No pivot variables for dsp ${this.dsp}`)
        }
      }
    },
    async loadColdStartAvailability () {
      this.$store.commit('setCurrentColdStartAvailability', null)
      const overview = this.$store.getters.getCurrentOverview
      if (overview) {
        const response = await this.$apiCaller.getColdStartAvailability(overview.id)
        if (this.$apiCaller.isResponseError(response)) {
          console.warn('Error when calling coldStart availability.')
          this.$store.commit('setErrorMessageWithResponse', response)
        } else {
          const data = response.data
          if (data.length) {
            this.$store.commit('setCurrentColdStartAvailability', data[0])
          } else {
            console.warn(`No coldStart availability for overview ${overview.id}`)
          }
        }
      } else {
        console.warn('No current overview, so no coldStart availability.')
      }
    },
    async loadAvailableKpis () {
      this.$store.commit('setAvailableKpis', null)
      const overview = this.$store.getters.getCurrentOverview
      if (overview) {
        const response = await this.$apiCaller.getAvailableKpis(overview.id, true)
        if (this.$apiCaller.isResponseError(response)) {
          console.warn('Error when calling availableKpis.')
          this.$store.commit('setErrorMessageWithResponse', response)
        } else {
          const data = response.data
          if (data.length) {
            this.$store.commit('setAvailableKpis', data)
          } else {
            console.warn(`No availableKpis for overview ${overview.id}`)
          }
        }
      } else {
        console.warn('No current overview, so no available Kpis.')
      }
    },
    createDefaultBaseline () {
      const defaultBaseline = {
        ai_performance: 'to_be_defined',
        baseline_type: 'other',
        baseline_io: null,
        before_start_date: null,
        before_end_date: null,
        ab_test_start_date: null,
        ab_test_end_date: null
      }
      this.$store.commit('setStartBaseline', defaultBaseline)
    },
    /**
     * if instru is created via dataform, get the group_name and put it in the first instruction
     */
    setValueDataForm () {
      if (this.isInstruCreatedViaDataform && this.dataFormGroupKey.group_name !== undefined) {
        this.changeAllInstructionFieldTo('group_name', this.dataFormGroupKey.group_name)
        this.changeAllInstructionFieldTo('is_active', true)
      }
    },
    /**
     * @param brief { BriefModel }
     * @param funnel { ConversionFunnelInstruction }
     */
    prefillInstructionWithTraderBrief (brief, funnel) {
      console.warn('[IoForm] Prefill instruction')
      const prefiller = new BriefPrefiller()
      prefiller.processPrefillInstructionWithBrief(brief, this.editedItem, this.dsp, this.funnel)
    },
    /*
    * OTTO MIN VIZ
    */
    openAlertErrorDialog () {
      this.dialogAlertError = true
    },
    closeAlertErrorDialog () {
      this.dialogAlertError = false
    },
    /*
    * BULK EDIT
    */
    addOrRemoveSelectedInstruction (value, itemId) {
      if (value === true) {
        this.selectedInstructions.push(itemId)
      } else if (value === false) {
        const index = this.selectedInstructions.indexOf(itemId)
        if (index !== -1) {
          this.selectedInstructions.splice(index, 1)
        }
      }
      this.changeSelectAllCheckboxStatus()
    },
    changeSelectAllCheckboxStatus () {
      if (this.selectedInstructions.length >= 1 && this.selectedInstructions.length < this.instructionContainer.length) {
        this.allInstructionsIndeterminate = true
        this.allInstructionsSelected = false
      } else if (this.selectedInstructions.length === this.instructionContainer.length) {
        this.allInstructionsIndeterminate = false
        this.allInstructionsSelected = true
      } else {
        this.allInstructionsIndeterminate = false
        this.allInstructionsSelected = false
      }
    },
    selectAllInstructions () {
      this.allInstructionsSelected = !this.allInstructionsSelected
      this.selectedInstructions = []
      if (this.allInstructionsSelected) {
        this.instructionContainer.forEach(instru => {
          this.selectedInstructions.push(instru.id)
        })
      }
      this.changeSelectAllCheckboxStatus()
    },
    openBulkEditDialog () {
      this.dialogBulkEdit = true
    },
    closeBulkEditDialog () {
      this.dialogBulkEdit = false
      this.$refs.bulkEditDialog.resetForm()
    },
    applyBulkEditChanges (data) {
      for (let index in this.instructionContainer) {
        let instru = this.instructionContainer[index]
        if (this.selectedInstructions.includes(instru.id)) {
          // Flat change -> true / false / no change
          instru.is_active = data.isActive != null && data.isActive.value != null ? data.isActive.value : instru.is_active
          instru.retry = data.retry != null && data.retry.value != null ? data.retry.value : instru.retry
          instru.fast_retry = data.fastRetry != null && data.fastRetry.value != null ? data.fastRetry.value : instru.fast_retry

          // Relative changes -> depending on if 'percent' or 'raw', apply a factor or a flat value if there is a value
          this.applyBulkEditRelativeChanges(instru, ['objective', 'KPI_CPA'], data.kpiCpa?.type, data.kpiCpa?.value)
          this.applyBulkEditRelativeChanges(instru, ['objective', 'max_CPM'], data.maxCpm.type, data.maxCpm.value)
          this.applyBulkEditRelativeChanges(instru, ['objective', 'offset'], data.offset.type, data.offset.value)
          this.applyBulkEditRelativeChanges(instru, ['objective', 'min_viz'], data.minViz.type, data.minViz.value)
          this.applyBulkEditRelativeChanges(instru, ['opti_ratio'], data.optiRatio.type, data.optiRatio.value)
          this.$refs.instructionFormComponents[index].$forceUpdate()
        }
      }
      this.closeBulkEditDialog()
    },
    applyBulkEditRelativeChanges (instru, keys, relativeType, relativeValue) {
      if (relativeType != null && relativeValue != null && relativeValue !== '') {
        // Get field to modify depending on keys combo
        let depth = instru
        let field = keys[keys.length - 1]
        if (keys.length >= 2) {
          keys.pop()
          for (let key of keys) {
            depth = depth == null ? instru[key] : depth[key]
          }
        }

        if (relativeType === 'perc') {
          const nbDecimals = 3
          depth[field] *= (1 + (Number(relativeValue) / 100))
          if (this.dsp === $YOUTUBE) {
            depth[field] = roundNum(depth[field], nbDecimals)
          }
        } else if (relativeType === 'raw') {
          // Special case for min_viz
          if (field === 'min_viz') {
            depth[field] = Number(relativeValue) / 100
          } else {
            depth[field] = Number(relativeValue)
          }
        }
      }
    },
    queryStatusLogs () {
      if (this.$refs.statusLogsComponentRef != null) {
        this.$refs.statusLogsComponentRef.queryStatusLogs()
      }
    },
    /**
     * @param tab {keyof typeof NAME_FOR_TAB}
     * @returns {ValueOf<typeof NAME_FOR_TAB>}
     */
    getNameForTab (tab) {
      return NAME_FOR_TAB[tab]
    },
    getNameForInstuctionSubView (insSubView) {
      return INSTRUCTIONS_SUB_VIEWS[insSubView]
    },
    // Default value when new Brief
    setUseCustomAlgoDefaultValue () {
      this.editedItem.use_custom_algo = this.editedItem.revenue_type === 'cost_plus'
    },
    openConfirmDialog () {
      this.confirmOption.text = 'Warning, This option will not apply if we are on the last day of the billing period, and there is no new billing detected at the IO level.'
      this.confirmOption.approveText = 'Proceed'
      this.confirmOption.cancelText = 'Cancel & edit'
      this.confirmOption.approveFunc = () => {
        this.insGetterSetter.allowSwitchIoPacing = true
        this.closeConfirmDialog()
      }
      this.confirmOption.cancelFunc = () => {
        this.insGetterSetter.allowSwitchIoPacing = false
        this.closeConfirmDialog()
      }
      this.confirmOption.dialog = true
    },
    closeConfirmDialog () {
      this.confirmOption.dialog = false
    },
    isSurcoucheForcedRatioAvailableForDsp () {
      if (this.dsp == null || !this.sfrAuthorizedDsps.includes(this.dsp)) {
        return false
      }
      return this.sfrAuthorizedDsps.length > 0
    },
    // Forced Ratio
    async setSfrAuthorizedDsps () {
      const response = await this.$apiCaller.getSurcoucheForcedRatioAuthorizedDsps()
      if (this.$apiCaller.isResponseError(response)) {
        console.warn('Error when calling getSurcoucheForcedRatioAuthorizedDsps.')
        this.$store.commit('setErrorMessageWithResponse', response)
      } else {
        this.sfrAuthorizedDsps = response.data
      }
    }
  },
  computed: {
    ...mapGetters(['isUserDebugger']),
    matchingOnePerDsp: {
      get: function () {
        if ([this.$APPNEXUS, this.$DBM].indexOf(this.dsp) !== -1) {
          return this.insGetterSetter.matchingLi
        } else if (this.dsp === this.$MEDIAMATH) {
          return this.insGetterSetter.matchingStrat
        } else if (this.dsp === this.$THETRADEDESK) {
          return this.insGetterSetter.matchingAd
        } else if (this.dsp === this.$FACEBOOK) {
          return this.insGetterSetter.matchingAdSet
        } else {
          return this.insGetterSetter.matchingLi
        }
      },
      set: function (value) {
        if ([this.$APPNEXUS, this.$DBM].indexOf(this.dsp) !== -1) {
          this.insGetterSetter.matchingLi = value
        } else if (this.dsp === this.$MEDIAMATH) {
          this.insGetterSetter.matchingStrat = value
        } else if (this.dsp === this.$THETRADEDESK) {
          this.insGetterSetter.matchingAd = value
        } else if (this.dsp === this.$FACEBOOK) {
          this.insGetterSetter.matchingAdSet = value
        }
      }
    },
    offsetCanBeSetted () {
      return this.activateExecutor === false || this.dsp !== this.$MEDIAMATH || this.executorVersion === '1'
    },
    allowChangingOptiOtto () {
      return [this.$MEDIAMATH, this.$DBM, this.$APPNEXUS, this.$YOUTUBE, this.$THETRADEDESK, this.$BEESWAX, this.$META].includes(this.dsp)
    },
    coldStartStatus () {
      const coldStartAvailability = this.$store.getters.getCurrentColdStartAvailability
      if (coldStartAvailability) {
        return coldStartAvailability.status_cold_start
      }
      // default value
      return false
    },
    optiOttoIcon () {
      if (!this.coldStartStatus) {
        return 'warning'
      }
      return 'adb'
    },
    tooltipOptiAuto () {
      const baseText = `Otto : ${this.editedItem.opti_auto == null ? 'false' : this.editedItem.opti_auto.toString()}`
      if (!this.coldStartStatus) {
        const coldStartAvailability = this.$store.getters.getCurrentColdStartAvailability
        let dateColdStart = 'NC'
        if (coldStartAvailability) {
          dateColdStart = this.$commonUtils.transformIsoDate(
            coldStartAvailability.insertion_date_utc,
            true,
            true,
            true
          )
        }

        return `${baseText} ${dateColdStart} : Cold Start won’t start today. You can still activate Otto and wait tomorrow for the first model.`
      }
      return baseText
    },
    /**
     * @returns {KpiValue[]}
     */
    kpiToOptimizeOptions () {
      if (Object.keys(this.$dspConfig[this.dsp]).indexOf('kpiToOptimizeOptions') === -1) {
        console.warn(`kpiToOptimizeOptions is not a key of dspConfig for the dsp ${this.dsp}. No options will be displayed for KpiToOptimize input.`)
        return []
      }
      return this.$dspConfig[this.dsp].kpiToOptimizeOptions
    },
    maxFrequencyValue () {
      return this.$isNotNullOrUndefined(this.editedItem.max_frequency) && typeof this.editedItem.max_frequency === 'object'
        ? this.editedItem.max_frequency
        : {}
    },
    targetFrequencyValue () {
      return this.$isNotNullOrUndefined(this.editedItem.target_frequency) && typeof this.editedItem.target_frequency === 'object'
        ? this.editedItem.target_frequency
        : {}
    },
    isEmptyInstructionContainer () {
      return !Array.isArray(this.instructionContainer) || this.instructionContainer.length === 0
    },
    /**
     * A error message must be displayed if an instruction has a opti_ratio but the other no.
     * If all or no instruction have a opti_ratio, no error message must be displayed
     */
    isDislayErrorMessageOptiRatio () {
      if (this.isEmptyInstructionContainer) {
        return false
      }
      let instruWithoutOptiRatio = this.instructionContainer.filter((item) => {
        return item.opti_ratio === undefined || item.opti_ratio === null || item.opti_ratio === ''
      })

      let nbWithoutOptiRatio = instruWithoutOptiRatio.length

      if (nbWithoutOptiRatio === 0 || nbWithoutOptiRatio === this.instructionContainer.length) {
        return false
      }

      for (let i in this.instructionContainer) {
        const instruction = this.instructionContainer[i]

        if (instruction.opti_ratio === undefined || instruction.opti_ratio === null || instruction.opti_ratio === '') {
          return true
        }
      }

      return false
    },
    isInstruCreatedViaDataform () {
      let formStatus = this.$store.getters.getFormStatus
      return this.dataFormGroupKey.group_key !== undefined && formStatus === this.$NEW && this.dataFormGroupKey.group_key.entity_type !== 'BULK_ADD'
    },
    displayDspUrl () {
      const overview = this.$store.getters.getCurrentOverview
      return !([this.$FACEBOOK, this.$KAYZEN].indexOf(this.dsp) !== -1 || this.$store.getters.getFormStatus === this.$NEW ||
        this.editedItem === undefined || this.editedItem === null ||
        (this.dsp === this.$DBM && !overview)
      )
    },
    tooltipUseCustomAlgo () {
      return this.editedItem.use_custom_algo ? 'Value : true' : 'Value : false'
    },
    editedItemIo: {
      get () {
        if (this.dsp === this.$BEESWAX && this.editedItem && this.editedItem.io) {
          return String(this.editedItem.io).split('_')[1]
        }
        return this.editedItem.io
      },
      set (value) {
        let io = value
        if (this.dsp === this.$BEESWAX) {
          io = `${this.editedItem.buzz_key}_${value}`
        }
        this.$set(this.editedItem, 'io', io)
      }
    },
    isLocalizationHeaderForm () {
      return this.$store.getters.getOpenDataForm ||
        this.$store.getters.getFormStatus === this.$EDIT ||
        isBetaDsp(this.dsp)
    },
    checkerBlock () {
      return this.$refs['io-form-header'].getLocalizationHeaderForm
    },
    acknowledgmentsAreAllOk () {
      return !this.acknowledgments.some(item => !item.is_ok)
    },
    /**
     * https://scibids-k.atlassian.net/jira/software/projects/PAIN/boards/10?selectedIssue=PAIN-1433
     */
    allowOttoMinViz () {
      if (!this.activateOttoMinViz) {
        return false
      }
      const overwriteViewability = this.getOvervriteViewabilityValueForDsp()
      return typeof this.insGetterSetter.lowestAllowedViewRate === 'number' && overwriteViewability
    },
    checkPreMepResult: {
      /**
       * @returns {CheckPreMepResult | null}
       */
      get: function () {
        return this.$store.getters.getCheckPreMepResult
      },
      /**
       * @param {CheckPreMepResult | null} newValue
       */
      set: function (newValue) {
        this.$store.commit('setCheckPreMepResult', newValue)
      }
    },
    overviewAttributes: {
      /**
       * @returns {OverviewAttribute | null}
       */
      get: function () {
        return this.$store.getters.getOverviewAttributes
      },
      /**
       * @param {OverviewAttribute | null} newValue
       */
      set: function (newValue) {
        this.$store.commit('setOverviewAttributes', newValue)
      }
    }
  },
  watch: {
    /**
     * optimizeRulesChecker is called every time 'instructionContainer' is updated
     */
    'instructionContainer': {
      immediate: true,
      deep: true,
      handler: function () {
        if (this.isEmptyInstructionContainer) {
          return
        }
        this.errorOptimizeRulesChecker = this.optimizeRulesChecker.isValid(this.instructionContainer.filter(item => item.is_active), this.dsp)
      }
    },
    'editedItem': {
      immediate: false,
      deep: true,
      handler: _.debounce(function () {
        this.displayBadgesIfErrors()
        this.errorBriefRulesChecker = this.briefRulesChecker.checkErrors(this.editedItem, this.instructionContainer, this.dsp)
        this.loadCheckPreMepValidator()
      }, 600)
    },
    'formIsValidated': {
      immediate: true,
      deep: true,
      handler: _.debounce(function () {
        this.displayBadgesIfErrors()
      }, 600)
    },
    'editedInstructions': {
      immediate: true,
      deep: true,
      handler: _.debounce(function () {
        this.displayBadgesIfErrors()
      }, 600)
    },
    '$store.getters.getCurrentBaseline': {
      immediate: true,
      deep: true,
      handler: _.debounce(function () {
        this.displayBadgesIfErrors()
      }, 600)
    },
    // END INHERIT WATCHER SYSTEM
    'insGetterSetter.trueKpiToOptimize': {
      deep: true,
      immediate: true,
      handler: function (value, previousValue) {
        if (value === 'CPA_PC') {
          if (['post click only', 'other'].indexOf(this.insGetterSetter.trueAttributionWindow) === -1) {
            this.insGetterSetter.trueAttributionWindow = 'post click only'
          }
        }
        const thirdPartyKpiHelper = new ThirdPartyKpiHelper()
        if (previousValue) {
          thirdPartyKpiHelper.processOnChangeKpiLvl(this.editedItem, this.dsp)
        }
      }
    },
    'insGetterSetter.clientIdPerDsp': {
      deep: true,
      immediate: false,
      handler: function (value) {
        if (value) {
          this.loadKeystoneConfig()
        }
      }
    },
    'insGetterSetter.overwriteLiBudget': {
      deep: true,
      immediate: false,
      handler: function (value) {
        if (!value) {
          this.insGetterSetter.allowSwitchIoPacing = false
        }
      }
    },
    currentTab: {
      immediate: true,
      handler: function (currentTab) {
        if (this.isInit) {
          this.$plausibleHelper.trackIoFormChangeTab(this.getNameForTab(currentTab))
          this.setSfrAuthorizedDsps()
        }
      }
    },
    currentInstructionSubView: {
      immediate: true,
      handler: function (currentInstructionSubView) {
        if (this.isInit && currentInstructionSubView == null) {
          this.currentInstructionSubView = this.defaultInstructionSubView
        }
      }
    },
    isInit: {
      handler: function (isInit) {
        if (isInit) {
          this.$plausibleHelper.trackIoFormChangeTab(this.getNameForTab(this.currentTab))
        }
      }
    },
    forceRatioSurcoucheViewOn: {
      handler: function (forceRatioSurcoucheViewOn) {
        if (this.isInit) {
          this.currentInstructionSubView = forceRatioSurcoucheViewOn ? 'ins-sub-view-1' : this.defaultInstructionSubView
        }
      }
    }
  }
}
</script>

<style>
.otto-adb {
  color: #ff8700;
}

.line-form-card .body-line-form {
  font-size: 12px;
  padding: 0.5em;
}

.line-form-card .card-action-footer {
  max-width: 48%;
  position: fixed;
  bottom: 0;
  background-color: whitesmoke;
  Z-INDEX: 222;
  padding-bottom: 2em;
}

.line-form-card .chips-title {
  width: 100%;
  font-size: 12px;
  height: 3em;
  background-color: #3788d8;
}

.line-form-card .chips-form-title {
  width: 80%;
  font-size: 12px;
  height: 3em;
  background-color: #3788d8;
}

.line-form-card .v-input {
  font-size: 11px;
}

.line-form-card .v-text-field {
  padding-top: 10px;
  margin-top: 1px;
}

.line-form-card .v-counter {
  font-size: 10px;
}

.line-form-card .v-btn--disabled.v-size--small {
  height: 20px;
  width: 20px;

}

.line-form-card .v-btn--disabled.v-size--small .v-icon{
  font-size: 18px
}

.line-form-card .v-btn--is-elevated.v-size--small {
  height: 20px;
  width: 20px;
}

.line-form-card .v-btn--is-elevated.v-size--small .v-icon {
  font-size: 18px
}

/*noinspection CssInvalidPropertyValue*/
.line-form-card .line-form-container {
  padding-top: 0;
  height: -webkit-fill-available;
  max-width: 1300px;
}

.line-form-card .title-line-form {
  padding-bottom: 0;
}

.line-form-card .remove-inline-div-alert {
  display: inline-block;
  width: 65px;
  font-size: 8px !important;
}

.line-form-card button.disabledbtn {
  background-color: lightcoral !important;
}

.line-form-card button.saveButton {
  background-color: cornflowerblue !important;
}

.alerting-fields-layout {
  font-size: 8px;
}

/* hide up/down arrows ("spinners") on input fields marked type="number" */
.no-spinners input[type=number] {
  -moz-appearance: textfield;
}

.no-spinners input[type=number]::-webkit-outer-spin-button,
.no-spinners input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.instruction-form-container {
  margin-bottom: 1em;
}

.title-io-div {
  color: white;
  font-weight: 500;
  padding: 1.5em;
}

.title-io-form {
  color: white;
  font-weight: 500;
  padding: 1em;
}

.title-io-div-save {
  padding-top: 0.7em;
}

.type-of-budget-select .v-messages__message.message-transition-enter-to,
.type-of-budget-select .v-messages__message {
  color: orange;
}

.line-form-card .v-tabs__div {
  height: 100%;
}

.advanced-span-in-title {
  color: white;
  font-size: 9px;
}

.flex.elevation-2.type-of-budget-constraints.xs12.sm12.md12 {
  margin-bottom: 1em;
}

.v-input.invisible-input-slot-error-message.v-text-field.theme--light {
  padding-top: 0;
}

.line-form-card .advanced-button-title {
  background: cornflowerblue;
  width: 22px;
  height: 21px;
  border-radius: 15%;
}

.line-form-card .advanced-button-title i {
  font-size: 18px;
}

.flex-errors-brief {
  color: red;
  padding-top: 8px;
  font-size: 11px;
}

.flex.xs6.sm6.md6.funnel-overwriting-form-layout {
  min-height: 250px;
}

.form-title-fix-margin .v-chip__content {
  margin-right: 19em;
}

.icon-button {
  border: 2px solid #3788D8;
}

.invisible-input-slot-error-message .v-input__slot {
  display: none;
  margin-bottom: 1em;
  margin-left: 1em;
}

.invisible-input-slot-error-message.error-color .v-messages__message {
  color: red;
}

.invisible-input-slot-error-message.hint-color .v-messages__message {
  color: orange;
}

.maxDivMaxMultLayout .v-input.v-text-field.theme--light {
  padding: 1em;
}

.font-form {
  font-size: 11px;
  color: gray;
  font-weight: 300;
}

.v-window.tabs-item-container {
  overflow-y: auto;
  height: 68vh;
}

.v-dialog.v-dialog--active.v-dialog--persistent {
  overflow-y: unset;
}

.lined-text-area-matching p {
  margin-bottom: unset;
}

.syntax-rules {
  background: white;
  padding: 1em;
}

.overwrite-btn-toggle .v-btn {
  min-width: 0;
  background-color: white !important;
}

.red-borders {
  border: 3px solid red !important;
}

.revcpm-typebudget-field {
  width: 6em;
}

.v-input--is-dirty.error--text .v-messages__message {
  color: red !important;
}

.maxDivMaxMultLayout .v-messages__message {
  color: orange;
}

.save-btn-multi {
  width: 6em;
}

.arrow-save-btn-multi {
  border-radius: 0;
  border-left: 1px solid lightblue;
  width: 1.7em !important;
}

.el-save-btn-multi {
  margin-left: 0;
  margin-right: 0;
  font-size: 10px !important;
}

.save-btn-container {
  text-align: end;
}

button.v-btn.v-btn--active.v-btn--flat.theme--light.green-button {
  color: forestgreen;
}

.type-of-budget-constraints {
  padding: 1em 4em 1em 1em;
}

.loading-barform {
  text-align: center;
  padding: 11em;
}

.line-form-card .remove-inline-div-btntoggle {
  font-size: 10px;
}

@media only screen
and (max-width: 1823px) {
  .line-form-card .remove-inline-div-btntoggle {
    width: 70px !important;
  }

  .line-form-card .v-label {
    font-size: 11px;
  }

  .v-item-group.overwrite-btn-toggle.theme--light.v-btn-toggle .v-btn {
    /*width: 48px;*/
    /*font-size: 8px;*/
    width: 25px;
    font-size: 6px;
  }
}

@media only screen
and (min-width: 1824px) {
  .line-form-card .remove-inline-div-btntoggle {
    width: 90px !important;
  }

  .v-item-group.overwrite-btn-toggle.theme--light.v-btn-toggle .v-btn {
    /*width: 48px;*/
    /*font-size: 8px;*/
    font-size: 8px;
    width: 47px;
    height: 50px;
  }

  .line-form-card .v-label {
    font-size: 12px;
  }
}

.line-form-card .v-tabs__item {
  padding-left: 0;
  padding-right: 0;
}

.toolbar-checkbox {
  padding-top: 50%;
}

.text-black {
  color: #000000;
}

.text-bold {
  font-weight: bold;
}

.size-icon-ins-header {
  font-size: 20px !important;
}

.toolbar-instru-form .v-btn {
  font-size: 12px;
  box-shadow: none;
  height: 100% !important;
  border-radius: 0;
}
.io-form-tabs-header .v-tab {
  min-width: 160px;
}
</style>
