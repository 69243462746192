import Component, { mixins } from 'vue-class-component'
import { getRightOptimizeField } from '../../utils/instructionsUtils'
import { stratMixin } from './stratMixin'
import { DspInstruction, InstructionDsp, InstructionStrat, Objective } from '../../types/instruction_type'
import { $APPNEXUS, $BEESWAX, $DBM, $MEDIAMATH, $THETRADEDESK, $YOUTUBE } from '../../config/dspConfig'
import _ from 'lodash'

export type ExplodeStep = 'opti_auto' | 'otto_min_viz'

@Component({})
export class explodeInstructionsMixin extends mixins(stratMixin) {
  errorCallApi = false
  noInstruStrat = false
  REQUIRED_PRECISION = 5

  getObjectiveKeysByDsp (
    dsp: InstructionDsp,
    optiAuto: boolean,
    ottoMinViz: boolean,
    explodeStep: ExplodeStep = 'opti_auto'
  ): (keyof Objective)[] {
    if ([$APPNEXUS, $DBM].includes(dsp)) {
      if (explodeStep === 'otto_min_viz') {
        if (optiAuto) {
          return [
            'max_CPM', 'min_viz'
          ]
        } else {
          return [
            'max_CPM', 'offset', 'KPI_CPA', 'min_viz'
          ]
        }
      } else if (explodeStep === 'opti_auto') {
        if (ottoMinViz) {
          return [
            'max_CPM', 'offset', 'KPI_CPA'
          ]
        } else {
          return [
            'max_CPM', 'offset', 'KPI_CPA', 'min_viz'
          ]
        }
      } else {
        throw new Error(`[explodeInstructionMixin] explodeStep ${explodeStep} is not handled in getObjectiveKeysByDsp`)
      }
    } else if ([$MEDIAMATH, $THETRADEDESK, $BEESWAX].includes(dsp)) {
      return [
        'max_CPM', 'offset', 'KPI_CPA'
      ]
    } else if ([$YOUTUBE].includes(dsp)) {
      return [
        'KPI_CPA'
      ]
    } else {
      throw new Error(`[explodeInstructionMixin] dsp ${dsp} is not handled in getObjectiveKeysByDsp`)
    }
  }
  resetErrorExplode () {
    this.errorCallApi = false
    this.noInstruStrat = false
  }
  async getExplodeInstructionStrat (
    instructionIds: number[],
    dsp: InstructionDsp,
    optiAuto: boolean,
    ottoMinViz: boolean,
    explodeStep: ExplodeStep = 'opti_auto'): Promise<Partial<DspInstruction>[]> {
    this.resetErrorExplode()
    let instructionStrat: InstructionStrat[] = []
    for (let i in instructionIds) {
      if (!instructionIds.hasOwnProperty(i) || instructionIds[i] === undefined || instructionIds[i] === null) {
        continue
      }
      const result = await this.$apiCaller.getInstructionsStrat(instructionIds[i], 'live', dsp)

      if (this.$apiCaller.isResponseError(result)) {
        console.warn('Error when calling instruction strat in explode.')
        this.$store.commit('setErrorMessageWithResponse', result)
        this.errorCallApi = true
        return []
      }

      instructionStrat = [...instructionStrat, ...result.data]
    }

    if (instructionStrat.length === 0) {
      console.warn('No instru strat to explode.')
      this.noInstruStrat = true
      return []
    }
    // regroup instru_strats by their objectives value.
    const instruStratByObjective = this.aggregateInstruStratByObjectiveGroupKey(instructionStrat, dsp, optiAuto, ottoMinViz)

    let newInstruOpti = []
    let lineItemIdList: Set<string | number> = new Set()
    let index = 0

    // for each of this objective, will create a instruction_opti with the objective.
    for (let key in instruStratByObjective) {
      const currentInstruStrat = instruStratByObjective[key][0]
      const groupName = `Group ${index + 1}`
      const currentLineItemId = this.__getCurrentLineItemIds(instruStratByObjective[key])
      currentLineItemId.forEach(id => lineItemIdList.add(id))
      const newInstru = this.createInstruOptiWithObjective(
        currentInstruStrat.objective,
        currentInstruStrat.opti_ratio,
        dsp,
        groupName,
        currentLineItemId,
        optiAuto,
        ottoMinViz,
        explodeStep
      )
      newInstruOpti.push(newInstru)
      index++
    }

    // will create a last instruction_opti with average of each of the different objective.
    const instruOptiAverage = this.createInstruOptiAverage(
      newInstruOpti.map(item => item.objective),
      newInstruOpti.map(item => item.opti_ratio),
      dsp,
      [...lineItemIdList],
      optiAuto,
      ottoMinViz,
      explodeStep
    )
    newInstruOpti.push(instruOptiAverage)
    return newInstruOpti
  }
  __getCurrentLineItemIds (instruStrats: InstructionStrat[]): (number | string)[] {
    return [...new Set(instruStrats.map(item => {
      return item[this.lineItemField]
    }))]
  }
  aggregateInstruStratByObjectiveGroupKey (
    instructionStrat: InstructionStrat[],
    dsp: InstructionDsp,
    optiAuto: boolean,
    ottoMinViz: boolean): Record<string, InstructionStrat[]> {
    let instruStratByObjective: Record<string, InstructionStrat[]> = {}

    for (let i in instructionStrat) {
      // we clone instruStrat here, cause in some case we will update the objective.KPI_CPA value,
      // and we don't want to modify the real instru_strat object.
      const instruStrat: InstructionStrat = _.cloneDeep(instructionStrat[i])

      // when dbm and AB_effective.A is set, we will use AB_effective.A in place of KPI_CPA
      // we modify directly the value of objective for simplify the rest of the logic.
      if (dsp === $DBM && instruStrat.AB_effective && typeof instruStrat.AB_effective.A === 'number') {
        instruStrat.objective.KPI_CPA = instruStrat.AB_effective.A
      }

      const stringifiedObj = this.stringifyGroupKeyObjective(
        instruStrat.objective,
        instruStrat.opti_ratio,
        dsp,
        optiAuto,
        ottoMinViz)
      if (stringifiedObj in instruStratByObjective) {
        instruStratByObjective[stringifiedObj].push(instruStrat)
      } else {
        instruStratByObjective[stringifiedObj] = [instruStrat]
      }
    }
    return instruStratByObjective
  }
  createInstruOptiWithObjective (
    objective: Objective,
    optiRatio: number,
    dsp: InstructionDsp,
    groupName: string,
    lineItemId: (number | string)[],
    optiAuto: boolean,
    ottoMinViz: boolean,
    explodeStep: ExplodeStep): Partial<DspInstruction> {
    const optimizeField = getRightOptimizeField(dsp)
    const lineItemIdTreated = dsp === $BEESWAX ? lineItemId.map(item => this.__replaceIdBeeswax(String(item))) : lineItemId
    const baseInstruOpti = {
      [optimizeField]: `only ,${lineItemIdTreated}`,
      group_name: groupName,
      opti_ratio: optiRatio,
      is_active: true
    }

    const objectiveKeys = this.getObjectiveKeysByDsp(dsp, optiAuto, ottoMinViz, explodeStep)
    const objectiveForDsp: Partial<Objective> = {}

    for (let i in objectiveKeys) {
      const key = objectiveKeys[i]
      objectiveForDsp[key] = objective[key]

      if (this.__keyMustBeRounded(key) && objectiveForDsp[key] !== undefined && objectiveForDsp[key] !== null) {
        objectiveForDsp[key] = this.__roundValue(objectiveForDsp[key])
      }
    }

    return {
      ...baseInstruOpti,
      objective: objectiveForDsp
    }
  }
  createInstruOptiAverage (
    objectiveList: Objective[],
    optiRatioList: number[],
    dsp: InstructionDsp,
    lineItemIdList: (number | string)[],
    optiAuto: boolean,
    ottoMinViz: boolean,
    explodeStep: ExplodeStep): Partial<DspInstruction> {
    const optimizeField = getRightOptimizeField(dsp)
    const filterNullAndUndef = (value: any) => value !== null && value !== undefined
    const lineItemIdListTreated = dsp === $BEESWAX
      ? lineItemIdList.map(item => this.__replaceIdBeeswax(String(item)))
      : lineItemIdList

    const baseInstruAverage: Partial<DspInstruction> = {
      [optimizeField]: `all but ,${lineItemIdListTreated.join(',')}`,
      group_name: 'All but group',
      opti_ratio: this.calcAverage(optiRatioList.filter(filterNullAndUndef)),
      is_active: true
    }

    const objectiveKeys = this.getObjectiveKeysByDsp(dsp, optiAuto, ottoMinViz, explodeStep)
    const objectiveForDsp: Partial<Objective> = {}

    for (let i in objectiveKeys) {
      const key = objectiveKeys[i]
      objectiveForDsp[key] = this.calcAverage(
        objectiveList.map(item => key in item ? item[key] : undefined).filter(filterNullAndUndef)
      )
      if (this.__keyMustBeRounded(key) && objectiveForDsp[key] !== undefined && objectiveForDsp[key] !== null) {
        objectiveForDsp[key] = this.__roundValue(objectiveForDsp[key])
      }
    }

    return {
      ...baseInstruAverage,
      objective: objectiveForDsp
    }
  }
  calcAverage (list: number[]): number | null {
    if (list.length === 0) {
      return null
    }
    return list.reduce((acc, current) => acc + current, 0) / list.length
  }
  stringifyGroupKeyObjective (
    objective: Objective,
    optiRatio: number,
    dsp: InstructionDsp,
    optiAuto: boolean,
    ottoMinViz: boolean
  ): string {
    const objectiveKeys = this.getObjectiveKeysByDsp(dsp, optiAuto, ottoMinViz)
    const objectiveForDsp: Partial<Objective> = {}

    for (let i in objectiveKeys) {
      const key = objectiveKeys[i]
      objectiveForDsp[key] = objective[key]
    }

    return JSON.stringify({
      ...objectiveForDsp,
      opti_ratio: optiRatio
    })
  }
  __replaceIdBeeswax (lineItemId: string): string {
    return lineItemId.split('_')[1]
  }
  __keyMustBeRounded (key: keyof Objective): boolean {
    return ['max_CPM', 'KPI_CPA', 'offset'].indexOf(key) !== -1
  }
  __roundValue (value: number): number {
    return this.$commonUtils.roundNum(value, this.REQUIRED_PRECISION)
  }
}
