
import Component, { mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import InstructionGetterSetter from '../../../../models/Overlay/InstructionGetterSetter'
import {
  FormTitle,
  ShowAdvanced,
  LinedTextarea,
  MatchingTitle,
  ConversionFunnel,
  AlertErrorBox
} from '../FormFragments'
import { $APPNEXUS, $BEESWAX, $DBM, $MEDIAMATH, $THETRADEDESK, $YOUTUBE } from '../../../../../config/dspConfig'
import BriefConstraints from './IOFormBriefFormFragments/BriefConstraints.vue'
import BriefFunnel from './IOFormBriefFormFragments/BriefFunnel.vue'
import BriefAlgoStrategy from './IOFormBriefFormFragments/BriefAlgoStrategy.vue'
import BriefDspOverwriting from './IOFormBriefFormFragments/BriefDspOverwriting.vue'
import BriefMarkupModule from './IOFormBriefFormFragments/BriefMarkupModule.vue'
import BriefCustomProdManagement from './IOFormBriefFormFragments/BriefCustomProdMgmt.vue'
import WaterfallManagement from './IOFormBriefFormFragments/WaterfallManagement.vue'
import { ioBriefMixins } from '../../../../mixins/instructions'
import { generalMixin } from '../../../../mixins/generalMixin'
import { activateMixin } from '../../../../mixins/activateMixin'
import { KpiValue } from '../../../../../types/brief_enum'
import * as rules from '../../../../rules/rules'
import BaselineComponent from '../FormFragments/BaselineComponent.vue'
import SiegeModel from '../../../../models/Keystone_v2/SiegeModel'
import SiegeSettingModel from '../../../../models/Keystone_v2/SiegeSettingModel'
import { snakeCaseConverterMixin } from '../../../../mixins/snakeCaseConverterMixin'
import { MarkupModuleModel } from '../../../../models/Keystone_v2/MarkupModuleModel'
import { CustomProdManagementModuleModel } from '../../../../models/Keystone_v2/CustomProdMgmtModuleModel'
import { recomposeAdvertiserIdBeeswax } from '../../../../../utils/instructionsUtils'
import BriefModel from '@/models/Briefs/BriefModel'
import { ConversionFunnelInstruction } from '../../../../../types/brief_type'
import BaseWarningDialog from '@/components/Common/BaseWarningDialog.vue'
import NewFeatureReferenceEncapsulation from '@/components/NewFeatureReferenceEncapsulation/NewFeatureReferenceEncapsulation.vue'
import MemberSettingModel from '@/models/Keystone_v2/MemberSettingModel'
import {
  allowSwitchPacingIo,
  perfFirstDevName,
  reachAndFrequency
} from '../../../../../config/featureReferenceConstant'
import { Baseline } from '../../../../../types/instruction_type'
import { ThirdPartyKpiHelper } from '../../../../../utils/ThirdPartyKpi/thirdPartyKpiHelper'
import NewFeatureReferenceModel from '@/models/Keystone_v2/NewFeatureReferenceModel'
import { OverwritingFieldConfig } from '../../../../../config/dspConfigPerDsp/dspConfigPerDspTypes'

export type AdvertiserSearchDataType = {search?: string, siegeId?: number, memberId?: number, parentId?: number}

@Component({
  components: {
    FormTitle,
    ShowAdvanced,
    LinedTextarea,
    MatchingTitle,
    ConversionFunnel,
    AlertErrorBox,
    BriefConstraints,
    BaselineComponent,
    BriefFunnel,
    BriefAlgoStrategy,
    BriefDspOverwriting,
    BriefMarkupModule,
    BriefCustomProdManagement,
    WaterfallManagement,
    BaseWarningDialog,
    NewFeatureReferenceEncapsulation
  }
})
export default class IOBriefTabForm extends mixins(ioBriefMixins, generalMixin, activateMixin, snakeCaseConverterMixin) {
  @Prop({ required: true, default: '' }) editedItemIo: String
  @Prop({ required: true, default: false }) isInstruCreatedViaDataform: boolean
  @Prop({ required: true, default: { label: 'untie "true_KPI_to_optimize" from "Constraints to respect"', value: false } }) untie: UntieType
  @Prop({ required: true, default: [] }) kpiToOptimizeOptions: Array<KpiValue>
  @Prop({ required: true, default: {} }) maxFrequencyValue: MaxFrequencyValueType
  @Prop({ required: true, default: {} }) targetFrequencyValue: MaxFrequencyValueType
  @Prop({ required: true, default: false }) isKpiToOptimizeDisabled: boolean
  @Prop({ required: true, default: null }) insGetterSetter: InstructionGetterSetter
  @Prop({ required: true, default: null }) brief: BriefModel
  @Prop({ required: true, default: null }) funnel: ConversionFunnelInstruction
  @Prop({ required: false, default: true }) isEditForm: boolean
  @Prop({ required: true }) keystoneMemberSetting: MemberSettingModel

  @Prop({ required: true })
  errorBriefRulesChecker: {errors: Array<any>, names: Array<any>}

  rules = rules

  input: InputType = {
    attributionWindow: [
      { text: 'As set in the DSP', value: 'As set in the DSP' },
      { text: 'post click only', value: 'post click only' },
      { text: 'other', value: 'other' }
    ],
    PVPC: ['day', 'minute'],
    executor: ['1', '2']
  }

  // these are the fields names according to the Siege Model, the 'prod' prefix part has to go away when pushing into the datastore
  customProdManagementFields: Array<keyof SiegeSettingModel> = [
    'prodUnderdeliveryCustom',
    'prodInverseOttoWaterfall',
    'prodOverdeliveryCustom',
    'prodRespectMinimumLiBudget'
  ]

  inheritedSiege: SiegeModel = null
  inheritedKeystoneSiegeSettings: SiegeSettingModel = null
  inheritedMarkupModule: MarkupModuleModel = null
  inheritedCustomProdMgmtModule: CustomProdManagementModuleModel = null

  briefMrkpModuleReady: boolean = false
  briefCustomProdMgmtReady: boolean = false

  messageNoBaselineIfNoOverview = 'A io can`t have a baseline if there is no corresponding overview in our system.'

  async mounted () {
    if (this.editedItem && (!this.isEditForm || this.checkIfIdExists)) {
      this.inheritedSiege = await this.getSiegeData()
      this.inheritedKeystoneSiegeSettings = await this.getInheritedKeystoneSiegeSettings()
      if (this.inheritedKeystoneSiegeSettings != null) {
        this.setInheritedModules()
      }
      this.loadChildrenComponents()
    }
  }

  loadChildrenComponents () {
    this.briefMrkpModuleReady = true
    this.briefCustomProdMgmtReady = true
  }

  setInheritedModules () {
    this.setInheritedMarkupModule()
    this.setInheritedCustomProdMgmtModule()
  }

  setInheritedMarkupModule () {
    this.inheritedMarkupModule = this.inheritedKeystoneSiegeSettings.markupModule
    let inheritedBoolean = this.insGetterSetter.markupManagementIsInheritedFromSeat
    this.insGetterSetter.markupManagementIsInheritedFromSeat = inheritedBoolean != null ? inheritedBoolean : true
  }

  setInheritedCustomProdMgmtModule () {
    this.inheritedCustomProdMgmtModule = new CustomProdManagementModuleModel(this.snakeCaseConverter(this.getCustomProdMgmtModelObject))
    let inheritedBoolean = this.insGetterSetter.customProdManagementIsInheritedFromSeat
    this.insGetterSetter.customProdManagementIsInheritedFromSeat = inheritedBoolean != null ? inheritedBoolean : true
  }

  get getCustomProdMgmtModelObject (): {[key: string]: any} {
    let object: {[key: string]: boolean} = {}
    this.customProdManagementFields.forEach((key: string) => {
      let formattedKey = key.substring(('prod').toString().length)[0].toLowerCase() + key.substring(('prod').toString().length).substring(1)
      object[formattedKey] = this.inheritedKeystoneSiegeSettings[key as keyof SiegeSettingModel]
    })
    return object
  }

  async getSiegeData () {
    const result = await this.$apiCaller.getAdvertisers(
      this.snakeCaseConverter({ externalId: this.getAdvertiserExternalId, memberExtId: this.insGetterSetter.clientIdPerDsp })
    )
    if (this.$apiCaller.isResponseError(result, true)) {
      console.warn('getSiegeData: Error when process')
    } else {
      // Length will be 0 if there is no real Siege in DB
      if (result.data.length === 0 || result.data[0].siege == null) {
        return null
      }
      return new SiegeModel(result.data[0]['siege'])
    }
  }

  get getAdvertiserExternalId () {
    let externalId = this.dsp === $BEESWAX
      ? recomposeAdvertiserIdBeeswax(this.editedItem.buzz_key, this.insGetterSetter.advertiserIdPerDsp.toString())
      : this.insGetterSetter.advertiserIdPerDsp.toString()
    return externalId
  }

  async getInheritedKeystoneSiegeSettings () {
    if (this.inheritedSiege != null) {
      let searchData: AdvertiserSearchDataType = { siegeId: this.inheritedSiege.id }
      return this.getSiegeSettings(searchData)
    }
  }

  async getSiegeSettings (searchData: AdvertiserSearchDataType) {
    const result = await this.$apiCaller.getKeystoneSiegeSettings(this.snakeCaseConverter(searchData))
    if (this.$apiCaller.isResponseError(result)) {
      console.warn('getInheritedKeystoneSiegeSettings: Error when process')
    } else {
      return new SiegeSettingModel(result.data[0])
    }
  }

  rulesBrief (): Array<any> {
    if ([$MEDIAMATH, $APPNEXUS, $DBM, $THETRADEDESK, $BEESWAX, $YOUTUBE].indexOf(this.dsp) !== -1) {
      return this.errorBriefRulesChecker.errors !== undefined ? this.errorBriefRulesChecker.errors : []
    }
    return []
  }

  getConvertedMarkupModule (): any {
    return (this.$refs['brief-markup-module'] as BriefMarkupModule).getConvertedMarkupModule()
  }
  getConvertedCustomProdMgmtModule (): any {
    return (this.$refs['brief-custom-prod-mgmt-module'] as BriefCustomProdManagement).getConvertedCustomProdMgmtModule()
  }

  displaySecondLvlKpi () {
    const thirdPartyHelper = new ThirdPartyKpiHelper()
    return thirdPartyHelper.isKpiSecondLvl(this.insGetterSetter.trueKpiToOptimize)
  }

  // GETTERS
  get checkIfIdExists () {
    return !(typeof this.editedItem.id === 'string' && this.editedItem.id === '')
  }
  get displayBordersAlgoStrategy (): boolean {
    return this.errorBriefRulesChecker.names.indexOf('errorExpectedMarginMaxA') !== -1 || this.displayBordersFunnel
  }
  get displayBordersFunnel (): boolean {
    return this.errorBriefRulesChecker.names.indexOf('errorKpiToOptimizeMaxA') !== -1
  }

  get perfFirstDevName (): string {
    return perfFirstDevName
  }

  get baseline (): Baseline {
    return this.$store.getters.getCurrentBaseline
  }

  set baseline (value: Baseline) {
    this.$store.commit('setCurrentBaseline', value)
  }

  get startBaseline (): Baseline {
    return this.$store.getters.getStartBaseline
  }

  get formPartWithError () {
    return this.$store.getters.getFormPartWithError
  }

  get hasErrorBaseline () {
    return this.formPartWithError.includes('baseline')
  }

  get currentOverview () {
    return this.$store.getters.getCurrentOverview
  }
  get kpiToOptimzeOptionData () {
    const nfrList = this.$store.getters.getCurrentNewFeatureReferences
    const nfrReachAndFrequency = nfrList.find((nfr: NewFeatureReferenceModel) => nfr.devName === reachAndFrequency)
    return nfrReachAndFrequency && nfrReachAndFrequency.isFeatureOn(this.dsp)
      ? this.getReachAndFrequencyKpiToOptimizeOptions
      : this.kpiToOptimizeOptions
  }
  // NFR
  get getReachAndFrequencyKpiToOptimizeOptions (): Array<KpiValue> {
    return this.kpiToOptimizeOptions.concat(['reach_and_frequency' as KpiValue])
  }
  launchTimeOutBadge () {
    this.$emit('launchTimeOutBadge')
  }

  // EMITTERS
  openAlertErrorDialog () {
    this.$emit('openAlertErrorDialog')
  }
  openConfirmDialog () {
    this.$emit('openConfirmDialog')
  }
}
